import React, { useState, useEffect } from 'react';
import { ALARM_CASE_SET } from 'services/table-text';
import styled from 'styled-components';
import { alarmCaseType } from 'types/alarm-T';
import { ReactComponent as PlusWhiteSVG } from 'assets/img/plusWhite.svg';
import SetAlarmLayout from 'layout/SetAlarmLayout';
import AlarmCaseSetting from 'components/AlarmCaseSetting/AlarmCaseSetting';
import { INIT_ALARM_CASE_LIST } from 'services/init-data';
import * as _ from 'lodash';
import {
  useAlarmCase,
  useMutationAlarmCase,
  useMutationDeleteAlarmCase,
  useMutationPutAlarmCase,
} from 'apiHooks/useAlarm';
import { apiCompleted, apiError } from 'apis/common';
import useOnModal from 'hooks/useOnModal';
import ConfirmModal from './ConfirmModal';
import { customError, customErrorMsg, findPutIds } from 'services/miscFunc';
import { useDispatch } from 'react-redux';
import { setErrorData } from 'store/modules/error';
import { validationCaseName, validationPeriodMinute } from 'validation/alarm';

interface IProps {
  selectedMenu: string;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
}

const SetAlarmCase: React.FC<IProps> = ({
  selectedMenu,
  setSelectedMenu,
  onClose,
}) => {
  const { rowData, onDeleteModal, onClickDeleteModal } = useOnModal();
  const [data, setData] = useState<alarmCaseType[]>([]);

  const { data: originData, refetch } = useAlarmCase(setData);
  const { mutateAsync } = useMutationAlarmCase();
  const { mutateAsync: mutatePutAsync } = useMutationPutAlarmCase();
  const { mutateAsync: mutateDeleteAsync } = useMutationDeleteAlarmCase();

  const dispatch = useDispatch();

  const onClickPlusAlarmCase = () => {
    const initData = { ...INIT_ALARM_CASE_LIST, priority: data.length + 1 };
    setData([...data, initData]);
  };

  useEffect(() => {
    if (data.length > 0) {
      const cvtData = data.map((item, idx) => ({ ...item, priority: idx + 1 }));
      setData(_.cloneDeep(cvtData));
    }
  }, [data.length]);

  const TitlePlusBtn = (
    <PlusButtonWrap>
      <div onClick={onClickPlusAlarmCase}>
        <PlusWhiteSVG /> <span>항목 추가</span>
      </div>
    </PlusButtonWrap>
  );

  const handleClickSave = async () => {
    try {
      const cvtIds = findPutIds(originData, data);
      const postData = data.filter((item) => !item.id);
      const putData = data.filter(
        (item) => item.id && cvtIds.includes(item.id),
      );

      const isValidCaseName = validationCaseName(postData, putData);
      const isValidPeriodMinute = validationPeriodMinute(
        postData,
        putData,
        data,
      );
      if (!isValidCaseName || !isValidPeriodMinute) return;

      const postRes = mutateAsync(postData);
      const putRes = mutatePutAsync(putData);

      const res = await Promise.all([postRes, putRes]);

      const isSuccess = res.every((item) => item.success);
      if (isSuccess) {
        apiCompleted();
        refetch();
        return;
      }
    } catch (e) {
      customErrorMsg('요청이 실패하였습니다.');
      return;
    }
  };

  const handleClickDelete = async () => {
    if (rowData.id) {
      const res = await mutateDeleteAsync(rowData!.id as number);
      if (res?.success) {
        apiCompleted();
        onClickDeleteModal();
        refetch();
      }
      return;
    }
    const filteredData = data.filter(
      (item: alarmCaseType) => rowData.priority !== item.priority,
    );
    apiCompleted();
    onClickDeleteModal();
    setData(filteredData);
  };

  const sortPriorityData = data.sort((a, b) => a.priority - b.priority);

  return (
    <>
      <SetAlarmLayout
        onClose={onClose}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        onClickSave={handleClickSave}
        main={
          <AlarmCaseSetting
            data={sortPriorityData}
            setData={setData}
            onClickDeleteBtn={onClickDeleteModal}
          />
        }
      >
        {TitlePlusBtn}
      </SetAlarmLayout>
      {onDeleteModal && (
        <ConfirmModal
          text={`레벨명: ${rowData.caseName || ''} 을(를) 삭제하시겠습니까?`}
          onClick={handleClickDelete}
          onClose={onClickDeleteModal}
        />
      )}
    </>
  );
};

const PlusButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 16px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  path {
    fill: ${({ theme }) => theme.TEXT_PRIMARY};
  }
`;

export default SetAlarmCase;
