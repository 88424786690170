const routePath = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  MAIN: '/main',
  ACCOUNT_MANAGE: '/account_manage',
  NOTICE_LIST: '/notice_list',
  NOTICE_VIEW: '/notice_view',
  NOT_FOUND: '/not_found',
};

export default routePath;
