/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from 'components/common/Modal';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getDefaultWrap } from './common/modalCss';
import { colors } from 'constants/colors';
import { Search } from '@mui/icons-material';
import { ALARM_LIST } from 'services/table-text';
import CommonTable from 'components/TablePanel/CommonTable';
import { useNav } from 'apiHooks/useNav';
import MyDatePicker from 'components/DatePicker/DatePicker';
import useDate from 'hooks/useDate';
import AlarmDetailModal from './AlarmDetailModal';
import { subjectAlarm } from 'services/enum';
import AlarmListSelect from 'components/AlarmListSelect/AlarmListSelect';
import ModalTitle from './common/ModalTitle';
import useOnModal from 'hooks/useOnModal';
import { useAlarmList, useMutationControlAllAlarms } from 'apiHooks/useAlarm';
import { INIT_ALARM_LIST } from 'services/init-data';
import Pagination from 'components/common/Pagination';
import { Tooltip, Typography } from '@mui/material';
import ConfirmModal from './ConfirmModal';
import { apiCompleted } from 'apis/common';
import { customErrorMsg, findHasDashboardNames } from 'services/miscFunc';

interface IProps {
  onClose: () => void;
}

const AlarmListModal: React.FC<IProps> = ({ onClose }) => {
  const [hasDashboardList, setHasDashboardList] = useState([]);
  const [onAllConfirmModal, setOnAllConfirmModal] = useState(false);

  const { data: domainList } = useNav();
  const { rowData, onDetailModal, onClickDetailModal } = useOnModal();
  const { mutateAsync: mutateAlarmControl, isLoading } =
    useMutationControlAllAlarms();

  const [alarmType, setAlarmType] = useState('ALL');
  const [alarmStatus, setAlarmStatus] = useState('ALL');
  const [dashboardId, setDashboardId] = useState(-1);

  const [curPage, setCurPage] = useState(1);
  const articlePerPage = 10;

  const {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    onClickDateBtn,
    onChangeInitDate,
  } = useDate();

  const [listApiPrms, setListApiPrms] = useState({
    sort: 'createdAt,desc',
    startDate,
    endDate,
    size: articlePerPage,
    page: curPage - 1,
  });

  const { data: alarmList = INIT_ALARM_LIST, refetch } =
    useAlarmList(listApiPrms);

  useEffect(() => {
    if (domainList?.domains) {
      findHasDashboardNames(domainList?.domains, setHasDashboardList);
    }
  }, [domainList?.domains]);

  useEffect(() => {
    setListApiPrms((prev) => ({ ...prev, page: curPage - 1 }));
  }, [curPage]);

  useEffect(() => {
    refetch();
  }, [listApiPrms.page]);

  const handleClickSearch = async () => {
    await setCurPage(1);
    await setListApiPrms((prev: any) => {
      const newPrms = { ...prev };
      if (alarmType === 'ALL') {
        delete newPrms.alarmType;
      } else {
        newPrms.alarmType = alarmType;
      }

      if (alarmStatus === 'ALL') {
        delete newPrms.alarmStatus;
      } else {
        newPrms.alarmStatus = alarmStatus;
      }

      if (dashboardId === -1) {
        delete newPrms.dashboardId;
      } else {
        newPrms.dashboardId = dashboardId;
      }

      newPrms.startDate = startDate;
      newPrms.endDate = endDate;

      return newPrms;
    });
  };

  const handleClickAllControlAlarms = async () => {
    const res = await mutateAlarmControl(undefined);
    if (res?.success) {
      setOnAllConfirmModal(false);
      const successCount = res.data.successCount;
      if (successCount > 0) {
        apiCompleted(
          `미확인 상태인 ${successCount}개의 알람이 모두 확인 처리 되었습니다.`,
        );
        refetch();
        return;
      }
      customErrorMsg('일괄 적용 될 알람이 존재하지 않습니다.');
    }
  };

  return (
    <Modal>
      <Wrap>
        <ModalTitle title="알람 목록" fillColor="#9a9a9a" onClose={onClose} />
        <MainWrap>
          <ContentWrap>
            <SelectWrap>
              <AlarmListSelect
                domainList={hasDashboardList}
                alarmType={alarmType}
                alarmStatus={alarmStatus}
                dashboardId={dashboardId}
                setAlarmType={setAlarmType}
                setAlarmStatus={setAlarmStatus}
                setDashboardId={setDashboardId}
              />

              <MyDatePicker
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                onClickDateBtn={onClickDateBtn}
              >
                <SearchIcon
                  onClick={async () => {
                    await handleClickSearch();
                    await refetch();
                  }}
                />
              </MyDatePicker>
            </SelectWrap>
            <TableWrap>
              <CommonTable
                tableHeaderData={ALARM_LIST}
                tableData={alarmList.content}
                subjectType={subjectAlarm.ALARM_LIST}
                onClickPlusBtn={onClickDetailModal}
              />
            </TableWrap>
            <Pagination
              curPage={curPage}
              articlePerPage={articlePerPage}
              data={Array.from({ length: alarmList.totalElements })}
              pagePerView={10}
              setCurPage={setCurPage}
            />
            <Tooltip
              title={
                <Typography sx={{ fontSize: '13px' }}>
                  모든 상태 미확인 → 확인 적용
                </Typography>
              }
              arrow
              placement="top"
            >
              <ConfirmBtn onClick={() => setOnAllConfirmModal(true)}>
                알람 일괄 적용
              </ConfirmBtn>
            </Tooltip>
            <ModalBtn onClick={onClose}>닫기</ModalBtn>
          </ContentWrap>
        </MainWrap>
      </Wrap>
      {onDetailModal && (
        <AlarmDetailModal
          data={rowData}
          onClose={onClickDetailModal}
          refetch={refetch}
        />
      )}
      {onAllConfirmModal && (
        <ConfirmModal
          text="미확인 알람들을 확인으로 일괄 적용 하시겠습니까?"
          isLoading={isLoading}
          onClick={handleClickAllControlAlarms}
          onClose={() => setOnAllConfirmModal(false)}
        />
      )}
    </Modal>
  );
};

const Wrap = styled.div`
  ${getDefaultWrap};
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  width: 1550px;
  height: 700px;
`;

const TableWrap = styled.div`
  width: 100%;
  height: 490px;
  overflow: auto;
  border-radius: 10px 10px 0 0;

  &::-webkit-scrollbar {
    width: 15px;
    margin-right: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
  input {
    vertical-align: middle;
    border-radius: 4px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 5%;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 10%;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 12%;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 35%;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 8%;
  }
  th:nth-child(7),
  td:nth-child(7) {
    width: 10%;
  }
  th:last-child,
  td:last-child {
    width: 5%;
  }

  td:nth-child(2) {
    white-space: pre-wrap;
    word-break: break-all;
  }

  td:nth-child(3) {
    white-space: pre-wrap;
    word-break: break-all;
  }
`;

const MainWrap = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  & span {
    color: ${({ theme }) => theme.TEXT_PRIMARY};
  }
`;

const SelectWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const ConfirmBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 100px;
  border: 0;
  outline: 0;
  padding: 8px 24px;
  border-radius: 6px;
  background: ${({ theme }) => theme.PRIMARY};
  /* background: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8); */
  color: ${colors.WHITE};
  font-weight: 500;
`;

const ModalBtn = styled.button`
  position: absolute;
  bottom: 0;
  width: 80px;
  padding: 8px 24px;
  align-self: flex-end;
  color: ${({ theme }) => theme.WHITE};
  background: ${({ theme }) => theme.BUTTON_THIRD};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
`;

const SearchIcon = styled(Search)`
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  margin-left: 16px;
  cursor: pointer;

  &:active {
    color: ${colors.BLUE_LIGHT};
  }
`;

export default AlarmListModal;
