import React, { ChangeEvent, FormEvent, useState } from 'react';
import ChangeNewPw from './common/ChangeNewPw';
import styled from 'styled-components';
import { ReactComponent as UpdateSVG } from 'assets/img/updating.svg';
import { colors } from 'constants/colors';
import { patchPw, putAccount, pwCheck } from 'apis/account-rest';
import { accountInfo } from 'store/modules/account';
import { useDispatch } from 'react-redux';
import { setErrorData } from 'store/modules/error';
import { validationPassword } from 'validation/account';
import { customConfirm, customError, customErrorMsg } from 'services/miscFunc';
import { setConfirmData } from 'store/modules/confirm';
interface IProps {
  accountInfo: accountInfo;
  onClose: () => void;
}
const ChangeAccountPw: React.FC<IProps> = ({ accountInfo, onClose }) => {
  const [pwFormData, setPwFormData] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });
  const dispatch = useDispatch();

  const { password, newPassword, confirmPassword } = pwFormData;
  const handleChangePw = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPwFormData({
      ...pwFormData,
      [name]: value,
    });
  };

  const handleClick = async () => {
    // origin 확인
    // new - confirm 확인
    const pwCheckPrms = {
      id: accountInfo.id,
      password,
    };

    const pwCheckResult = await pwCheck(pwCheckPrms);

    if (!pwCheckResult.status) {
      customErrorMsg('기존 비밀번호가 일치하지 않습니다.');
      return;
    }
    const isSamePw = newPassword === confirmPassword;
    const isValidationPw =
      validationPassword(newPassword).status ||
      validationPassword(confirmPassword).status;
    if (!isSamePw) {
      customErrorMsg('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
      return;
    }

    if (!isValidationPw) {
      customErrorMsg(
        '특수문자, 영문, 숫자를 포함하여\n 8글자 이상 16글자 이하를 입력해주세요.',
      );
      return;
    }

    const prms = {
      id: accountInfo.id,
      password: newPassword,
    };

    const res = await patchPw(prms);
    if (res?.success) {
      dispatch(setConfirmData(customConfirm('요청이 적용되었습니다.')));
      onClose();
    }
    // api
  };
  return (
    <ChangeNewPw
      isAccountInfo
      password={password}
      newPassword={newPassword}
      confirmPassword={confirmPassword}
      onChangePw={handleChangePw}
      onClick={handleClick}
      onClose={onClose}
    >
      <AlarmWrap>
        <UpdateIcon />
        <p>계정 비밀번호 변경</p>
      </AlarmWrap>
      <DescParagraph>아래에 새 비밀번호를 입력해주세요.</DescParagraph>
    </ChangeNewPw>
  );
};

const AlarmWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  > p {
    font-size: 16px;
    font-weight: 600;
  }

  > svg {
    path,
    polyline {
      stroke: ${({ theme }) => theme.TEXT_PRIMARY} !important;
    }
  }
`;

const UpdateIcon = styled(UpdateSVG)`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const DescParagraph = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.TEXT_PRIMARY}66;
  line-height: 20px;
`;

export default ChangeAccountPw;
