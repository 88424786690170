/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ReactComponent as leftArrowSvg } from 'assets/img/leftArrowPagi.svg';
import { ReactComponent as rightArrowSvg } from 'assets/img/rightArrowPagi.svg';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { getPagination, getTotalPage } from 'utils/paging';

interface IProps {
  curPage: number;
  data: any[];
  articlePerPage: number;
  pagePerView?: number;
  setCurPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: React.FC<IProps> = ({
  curPage,
  data,
  pagePerView,
  articlePerPage,
  setCurPage,
}) => {
  const pageInfos = getPagination({
    currentPage: curPage,
    pagePerView: pagePerView || 5,
    articlePerPage,
    total: data.length,
  });

  const handleChangePage = (move: string) => {
    if (move === 'left' && pageInfos.first.movable) {
      setCurPage(curPage - 1);
    }
    if (move === 'right' && pageInfos.last.movable) {
      setCurPage(curPage + 1);
    }
  };

  if (data.length === 0) return <Wrap></Wrap>;
  // 예시
  return (
    <Wrap>
      <LeftArrowIcon onClick={() => handleChangePage('left')} />
      {pageInfos.pages.map((num) => {
        return (
          <PerPage
            key={num}
            $clicked={num === curPage}
            onClick={() => setCurPage(num)}
          >
            {num}
          </PerPage>
        );
      })}
      <RightArrowIcon onClick={() => handleChangePage('right')} />
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-top: 16px;
`;

const ArrowButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: transparent;
`;

const LeftArrowIcon = styled(leftArrowSvg)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const RightArrowIcon = styled(rightArrowSvg)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const PerPage = styled.span<{ $clicked?: boolean }>`
  font-weight: 400;
  font-size: 14.5px;
  line-height: 100%;
  color: ${({ $clicked }) =>
    $clicked ? colors.BLUE_BASE : colors.NEUTRAl_300} !important;
  cursor: pointer;
`;

export default Pagination;
