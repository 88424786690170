import React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';

const AlarmCaseDesc = () => {
  const theme = useTheme();

  const descTooltip = (
    <Wrap>
      <span>
        <LooksOneIcon /> 우선 순위가 1에 가까울수록 위험도가 높음
      </span>
      <span>
        <LooksTwoIcon /> 순위 변경은 Drag & Drop으로 가능
      </span>
    </Wrap>
  );
  return (
    <Tooltip title={descTooltip} arrow>
      <HelpIcon
        sx={{
          color: theme.BUTTON_BG_THIRD,
          width: '20px',
          height: '20px',
          marginLeft: '4px',
          verticalAlign: 'middle',
          paddingBottom: '2px',
        }}
      />
    </Tooltip>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  padding: 8px 8px;
  width: 300px;

  > span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export default AlarmCaseDesc;
