import Header from 'components/Header/Header';
import React from 'react';
import { styled } from 'styled-components';
interface IProps {
  nav: React.ReactNode;
  main: React.ReactNode;
}

const IemsLayout: React.FC<IProps> = ({ nav, main }) => {
  return (
    <Wrap>
      <Header />
      <BodyWrap>
        {nav}
        {main}
      </BodyWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BodyWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export default IemsLayout;
