/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { colors } from 'constants/colors';
import routePath from 'constants/route';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { css, styled } from 'styled-components';
import { ReactComponent as LogoutSvg } from 'assets/img/logout.svg';
import { ReactComponent as PersonSvg } from 'assets/img/person.svg';
import { useAppSelect } from 'store/configureStore.hooks';
import AccountInfo from 'components/AccountInfo/AccountInfo';
import { useDispatch } from 'react-redux';
import { setAccountInfoReset } from 'store/modules/account';
import { setPageInfoReset } from 'store/modules/pageInfo';
import CheckedPwModal from 'components/ModalContents/CheckedPwModal';
import ConfirmModal from 'components/ModalContents/ConfirmModal';
import logoutLargeIcon from 'assets/img/logoutLargeIcon.jpg';
import RemoteImg from '@mui/icons-material/SettingsRemote';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { setIsRemote } from 'store/modules/remoteControl';
import { Tooltip, Typography } from '@mui/material';
import AlarmListModal from 'components/ModalContents/AlarmListModal';
import SetAlarm from 'pages/SetAlarm';
import { useCiImg } from 'apiHooks/useDashboard';
import HeaderAlarm from 'components/HeaderAlarm/HeaderAlarm';
import HeaderAccount from 'components/HeaderAccount/HeaderAccount';
import WeatherImg from '@mui/icons-material/WbSunny';
import Weather from 'components/Weather/Weather';
import HeaderAppearance from 'components/HeaderAppearance/HeaderAppearance';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useMainNotice } from 'apiHooks/useNotice';

const Header = () => {
  const [onAccountInfo, setAccountInfo] = useState(false);
  const [onCheckedPwModal, setOnCheckedPwModal] = useState(false);
  const [onAlarmSetModal, setOnAlarmSetModal] = useState(false);
  const [onAlarmListModal, setOnAlarmListModal] = useState(false);
  const [onLogoutModal, setOnLogoutModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role } = useAppSelect((state) => state.accountInfo.info);
  const { isRemote } = useAppSelect((state) => state.remoteControlInfo);
  const { data: ciImgData } = useCiImg();

  const handleClickAccount = () => {
    if (location.pathname === routePath.ACCOUNT_MANAGE) return;
    setOnCheckedPwModal(true);
  };

  const handleClickAlarmSet = () => {
    setOnAlarmSetModal(true);
  };

  const handleClickDashboard = () => {
    navigate(routePath.MAIN);
  };

  const handleClickLogout = () => {
    dispatch(setPageInfoReset());
    dispatch(setAccountInfoReset());
    navigate(routePath.LOGIN);
  };

  const isManager = role !== 'USER';
  const ci = ciImgData?.size ? URL.createObjectURL(ciImgData) : undefined;
  const isOnpremise = process.env.REACT_APP_ONPREMISE === 'true';
  return (
    <Wrap>
      <EnterpriseWrap onClick={handleClickDashboard}>
        {ci && <img src={ci} alt="ci" />}
        {!ci && <EmptyCi />}
      </EnterpriseWrap>
      <NavWrap>
        <Home
          onClick={handleClickDashboard}
          $isMain={location.pathname === routePath.MAIN}
        >
          통합 대시보드
        </Home>
      </NavWrap>

      <AccountWrap>
        {!isOnpremise && (
          <Tooltip title={<Weather />} arrow placement="bottom">
            <WeatherIcon />
          </Tooltip>
        )}

        <HeaderAppearance
          isManager={isManager}
          onAccountInfo={onAccountInfo}
          setAccountInfo={setAccountInfo}
          onClickAccount={handleClickAccount}
        />

        <HeaderAlarm
          isManager={isManager}
          setOnAlarmListModal={setOnAlarmListModal}
          setOnAlarmSetModal={setOnAlarmSetModal}
        />

        <HeaderAccount
          isManager={isManager}
          onAccountInfo={onAccountInfo}
          setAccountInfo={setAccountInfo}
          onClickAccount={handleClickAccount}
        />

        <Tooltip title={<Typography fontSize={14}>로그아웃</Typography>}>
          <LogoutIcon onClick={() => setOnLogoutModal(true)} />
        </Tooltip>
      </AccountWrap>
      {onAccountInfo && <AccountInfo onClose={() => setAccountInfo(false)} />}
      {onAlarmSetModal && (
        <SetAlarm onClose={() => setOnAlarmSetModal(false)} />
      )}
      {onAlarmListModal && (
        <AlarmListModal onClose={() => setOnAlarmListModal(false)} />
      )}
      {onCheckedPwModal && (
        <CheckedPwModal onClose={() => setOnCheckedPwModal(false)} />
      )}
      {onLogoutModal && (
        <ConfirmModal
          img={logoutLargeIcon}
          text="로그아웃 하시겠습니까?"
          size={140}
          onClose={() => setOnLogoutModal(false)}
          onClick={handleClickLogout}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 18px;
  right: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 63px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.ROOT_BG};
  padding: 0 44px 0 6px;
  min-width: 1000px;
  z-index: 100;
  svg {
    color: ${({ theme }) => theme.TEXT_PRIMARY};
  }
`;

const EnterpriseWrap = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  gap: 12px;
  > img {
    width: 220px;
    height: 50px;
    padding: 2px 4px;
    background: ${({ theme }) => theme.ROOT_BG};
    border-radius: 8px;
    object-fit: cover;
  }
  cursor: pointer;
`;

const NavWrap = styled.nav`
  display: flex;
  gap: 10px;
  > div:first-child {
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 12px;

    &:hover {
      background: ${({ theme }) => theme.CARD_BG_PRIMARY};
      color: ${({ theme }) => theme.TEXT_PRIMARY};
      font-weight: bold;
    }
  }
`;

const Home = styled.div<{ $isMain: boolean }>`
  ${({ $isMain, theme }) =>
    $isMain &&
    `
      background: ${theme.CARD_BG_PRIMARY};
      color: ${theme.TEXT_PRIMARY};
      font-weight: bold;
  `}
`;

const AccountWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  gap: 28px;

  > button {
    border: none;
    background:
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
          at top center,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 120%
        )
        #989898;
    background-blend-mode: multiply, multiply;
    color: ${colors.WHITE};
    padding: 5px 12px;
    border-radius: 6px;
  }
`;

const WeatherIcon = styled(WeatherImg)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const RemoteIcon = styled(RemoteImg)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const NotiIcon = styled(NotificationsIcon)`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const LogoutIcon = styled(LogoutSvg)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const PersonIcon = styled(PersonSvg)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const EmptyCi = styled.div`
  width: 220px;
  height: 50px;
  background: transparent;
  border-radius: 8px;
`;

const Menu = styled.div`
  position: absolute;
  top: 48px;
  right: 110px;

  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  z-index: 1;
`;

const MenuItem = styled.div`
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #444;
  }
`;

const NoticeIcon = styled(CampaignIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const NoticeWrap = styled.div`
  width: 230px;
  height: 45px;
  margin-left: 10px;
  overflow: hidden;
`;

const NoticeElement = styled.div`
  width: 100%;
  height: 45px;
  padding: 15px 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    > span:nth-child(2) {
      text-decoration: underline;
    }
  }
`;

const Tag = styled.span`
  background: #58595b;
  padding: 3px 5px;
  margin-right: 5px;
  border-radius: 5px;
  color: #fff;
`;

export default Header;
