/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { getWindDirection, weatherImg } from 'services/miscFunc';
import needleIcon from '@mui/icons-material/Navigation';
import { colors } from 'constants/colors';

interface IProps {
  data: any;
}
const CurrentWeather: React.FC<IProps> = ({ data }) => {
  const temp = data.data.find((item: any) => item.category === 'T1H').obsrValue;
  const humid = data.data.find(
    (item: any) => item.category === 'REH',
  ).obsrValue;
  const windSpeed = data.data.find(
    (item: any) => item.category === 'WSD',
  ).obsrValue;
  const rainfall = data.data.find(
    (item: any) => item.category === 'RN1',
  ).obsrValue;
  const pty = data.data.find((item: any) => item.category === 'PTY').obsrValue;

  const windDegree = Number(
    data.data.find((item: any) => item.category === 'VEC').obsrValue,
  );
  const windDirection = getWindDirection(windDegree);
  console.log(windDirection);

  const imgData = weatherImg(Number(pty), Number(data.skyData));
  return (
    <Wrap>
      <h1>현재 날씨</h1>
      <ImgWrap>
        <img src={imgData.img} alt="cloud" />
        <span>{imgData.text}</span>
      </ImgWrap>

      <InfoWrap>
        <div>
          <span>온도</span>
          <span>{`${temp} °C`}</span>
        </div>
        <div>
          <span>습도</span>
          <span>{`${humid} %`}</span>
        </div>
        <div>
          <span>풍속</span>
          <span>{`${windSpeed} m/s`}</span>
        </div>
        <div>
          <span>강수량</span>
          <span>{`${rainfall} mm`}</span>
        </div>
        <div>
          <span>풍향</span>
          {windDirection}
          <NeedleWrap>
            <Needle
              degree={windDegree}
              sx={{ width: '16px', height: '16px', color: colors.NEUTRAl_100 }}
            />
          </NeedleWrap>
        </div>
      </InfoWrap>
    </Wrap>
  );
};

const NeedleWrap = styled.div`
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-top: 4px;
`;

const Needle = styled(needleIcon)<{ degree: number }>`
  transform: rotate(${(props) => props.degree}deg);
`;

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 14px;

  > h1 {
    font-size: 15px;
    font-weight: 700;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    text-align: center;
    color: ${colors.BLUE_LIGHT};
    font-weight: 700;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  > div {
    display: flex;
    align-items: center;
    gap: 12px;

    > span:nth-child(1) {
      width: 100px;
      font-weight: 700;
    }
  }
`;

export default CurrentWeather;
