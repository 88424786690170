import React, { useState } from 'react';
import { subjectAlarm } from 'services/enum';
import SetAlarmCase from 'components/ModalContents/SetAlarmCase';
import SetAlarmStandard from 'components/ModalContents/SetAlarmStandard';
import SetAlarmOperating from 'components/ModalContents/SetAlarmOperating';
interface IProps {
  onClose: () => void;
}
const SetAlarm: React.FC<IProps> = ({ onClose }) => {
  const [selectedMenu, setSelectedMenu] = useState(subjectAlarm.CASE_SETTING);

  // switch ( main ) 메뉴에 해당하는 컴포넌트들을 넣어주면 됨

  switch (selectedMenu) {
    case subjectAlarm.CASE_SETTING:
      return (
        <SetAlarmCase
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          onClose={onClose}
        />
      );
    case subjectAlarm.STANDARD_SETTING:
      return (
        <SetAlarmStandard
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          onClose={onClose}
        />
      );
    case subjectAlarm.OPERATING_STATUS_SETTING:
      return (
        <SetAlarmOperating
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};

export default SetAlarm;
