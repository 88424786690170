/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import styled from 'styled-components';
import { Tooltip, Typography } from '@mui/material';
const AccountSx = { width: '24px', height: '24px', cursor: 'pointer' };
interface IProps {
  isManager: boolean;
  onAccountInfo: boolean;
  setAccountInfo: any;
  onClickAccount: () => void;
}
const HeaderAccount: React.FC<IProps> = ({
  isManager,
  onAccountInfo,
  setAccountInfo,
  onClickAccount,
}) => {
  return (
    <Tooltip
      title={
        <Menu>
          <MenuItem onClick={() => setAccountInfo(!onAccountInfo)}>
            <PersonSearchIcon />
            <Typography fontSize={14}>사용자 정보</Typography>
          </MenuItem>
          {isManager && (
            <MenuItem onClick={onClickAccount}>
              <PersonAddIcon />
              <Typography fontSize={14}>사용자 관리</Typography>
            </MenuItem>
          )}
        </Menu>
      }
    >
      <PersonIcon sx={AccountSx} />
    </Tooltip>
  );
};

const Menu = styled.div`
  /* background-color: #333; */
  border-radius: 4px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 14px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #444;
  }
`;

export default HeaderAccount;
