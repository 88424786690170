import React from 'react';
import { styled } from 'styled-components';
import { domainT } from 'types/dashboard-T';
import { ReactComponent as ClipBoardSVG } from 'assets/img/clipBoard.svg';
import { colors } from 'constants/colors';
import { ReactComponent as RightArrowSVG } from 'assets/img/rightArrow.svg';
import { useLocation } from 'react-router-dom';
import routePath from 'constants/route';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ArrowRight } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useAppSelect } from 'store/configureStore.hooks';
import { setCollapsedIds } from 'store/modules/pageInfo';
interface IProps {
  data: domainT;
  depth: number;
  clickedItem: number;
  onClickItem: (id: number) => void;
}
const DashboardItem: React.FC<IProps> = ({
  data,
  depth = 0,
  clickedItem,
  onClickItem,
}) => {
  const dispatch = useDispatch();
  const { collapsedIds } = useAppSelect((state) => state.pageInfo);

  const hasDashboardId = data?.dashboard?.id;
  const location = useLocation();
  const isCollapsed = collapsedIds.includes(data?.id);

  const exceptionRoute = [
    routePath.MAIN,
    routePath.NOTICE_LIST,
    routePath.ACCOUNT_MANAGE,
  ];

  const onClickParents = () => {
    // collapsedIds에 data.id가 없다면 추가하고, 있으면 제거
    dispatch(
      setCollapsedIds(
        collapsedIds.includes(data.id)
          ? collapsedIds.filter((id) => id !== data.id)
          : [...collapsedIds, data.id],
      ),
    );
  };

  return (
    <Wrap $depth={depth}>
      <SubItem
        onClick={() => {
          hasDashboardId && onClickItem(hasDashboardId!);
          !hasDashboardId && onClickParents();
        }}
        $allowClick={!!hasDashboardId}
        $clicked={
          clickedItem === hasDashboardId &&
          !exceptionRoute.includes(location.pathname)
        }
        $isCollapsed={isCollapsed}
      >
        <span>
          {hasDashboardId && <ClipBoardIcon />}
          {data.name}
        </span>
      </SubItem>
      <div>
        <TransitionGroup>
          {!isCollapsed &&
            data.children.map((child: domainT) => (
              <CSSTransition key={child.id} timeout={300} classNames="fade">
                <DashboardItem
                  data={child}
                  depth={depth + 1}
                  clickedItem={clickedItem}
                  onClickItem={onClickItem}
                />
              </CSSTransition>
            ))}
        </TransitionGroup>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div<{ $depth: number }>`
  margin-left: ${({ $depth }) => ($depth > 0 ? '18px' : undefined)};
  border-left: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}3E;

  > div {
    display: flex;
    flex-direction: column;
    /* margin-top: 12px; */
  }
  .fade-enter {
    opacity: 0;
    transform: translateY(10px); /* 처음에 살짝 아래에서 시작 */
  }

  .fade-enter-active {
    opacity: 1;
    transform: translateY(0); /* 원래 위치로 이동 */
    transition:
      opacity 300ms,
      transform 300ms;
  }

  .fade-exit {
    opacity: 1;
    transform: translateY(0); /* 원래 위치 */
  }

  .fade-exit-active {
    opacity: 0;
    transform: translateY(-10px); /* 위로 올라가면서 사라짐 */
    transition:
      opacity 300ms,
      transform 300ms;
  }
`;

const SubItem = styled.span<{
  $allowClick: boolean;
  $clicked: boolean;
  $isCollapsed?: boolean;
}>`
  display: flex;
  gap: 8px;
  align-items: center;
  /* border-left: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}3E; */
  /* padding-left: 12px; */
  padding: 2px 10px;
  /* pointer-events: ${({ $allowClick }) => ($allowClick ? 'auto' : 'none')}; */
  /* cursor: ${({ $allowClick }) => ($allowClick ? 'pointer' : 'auto')}; */
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 15px;
    border-radius: 12px;
    color: ${({ $clicked, theme }) =>
      $clicked ? theme.WHITE : theme.TEXT_PRIMARY};
    font-weight: ${({ $clicked, $allowClick }) =>
      $clicked || !$allowClick ? 600 : 400};
    background: ${({ $clicked, theme }) => ($clicked ? theme.PRIMARY : 'none')};
    transition: ease-in-out 0.2s;

    ${({ $allowClick, $isCollapsed }) =>
      !$allowClick &&
      ` 
      font-size: 14px;
      &::after {
        content: '>';
        display: inline-block;
        margin-left: 8px;
        transition: transform 0.3s; // 애니메이션 추가
        transform: rotate(${$isCollapsed ? 0 : 90}deg); 
      }
    `}
  }
`;

const ClipBoardIcon = styled(ClipBoardSVG)`
  width: 18px;
  height: 18px;
`;

const RightArrowIcon = styled(RightArrowSVG)`
  width: 12px;
  height: 12px;
`;

export default DashboardItem;
