import { useNav } from 'apiHooks/useNav';
import AlarmOperingSetting from 'components/AlarmOperatingSetting/AlarmOperatingSetting';
import SetAlarmLayout from 'layout/SetAlarmLayout';
import React, { useState } from 'react';
interface IProps {
  selectedMenu: string;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
}
const SetAlarmOperating: React.FC<IProps> = ({
  selectedMenu,
  setSelectedMenu,
  onClose,
}) => {
  const [data, setData] = useState([
    {
      dashboardName: '구양실업 1호',
      targetSensorId: 0,
      range: [],
      activeColor: '#5427c1',
      inactiveColor: '#969696',
    },
  ]);
  const [dashboardId, setDashboardId] = useState<number>(-1);
  const { data: domainList } = useNav();

  return (
    <SetAlarmLayout
      selectedMenu={selectedMenu}
      setSelectedMenu={setSelectedMenu}
      main={
        <AlarmOperingSetting
          data={data}
          dashboardId={dashboardId}
          domainList={domainList}
          setData={setData}
          setDashboardId={setDashboardId}
        />
      }
      onClose={onClose}
      onClickSave={() => {}}
    />
  );
};

export default SetAlarmOperating;
