export const ACCOUNT_MANAGE = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '이름',
    en: 'name',
  },
  {
    kr: '계정',
    en: 'loginId',
  },
  {
    kr: '전화번호',
    en: 'phone',
  },
  {
    kr: '이메일',
    en: 'email',
  },
  {
    kr: '계정 권한',
    en: 'role',
  },
  {
    kr: '마지막 로그인',
    en: 'lastLoginTime',
  },
  {
    kr: '옵션',
    en: 'option',
  },
];

export const SEARCH_ENTERPRISE = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '기업명',
    en: 'name',
  },
  {
    kr: '코드',
    en: 'code',
  },
];

export const HIDE_SENSOR = [
  {
    kr: '체크박스',
    en: 'checkbox',
  },
  {
    kr: '센서명',
    en: 'name',
  },
  {
    kr: '등록 번호',
    en: 'id',
  },
];

export const CONTROLLER_LOG = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '날짜',
    en: 'createdAt',
  },
  {
    kr: '제어 내용',
    en: 'log',
  },
  {
    kr: '제어자',
    en: 'username',
  },
];

export const ALARM_CASE_SET = [
  {
    kr: '우선 순위',
    en: 'priority',
  },
  {
    kr: '레벨명',
    en: 'caseName',
    type: 'input',
  },
  {
    kr: '색상',
    en: 'color',
    type: 'color',
  },
  {
    kr: '알람 주기(분)',
    en: 'periodMinute',
    type: 'number',
  },
  {
    kr: '알람 문자 발송',
    en: 'isPushSms',
    type: 'checkbox',
  },
  {
    kr: '알람 메일 발송',
    en: 'isPushMail',
    type: 'checkbox',
  },
  {
    kr: '알람 팝업 사용',
    en: 'isPushFloat',
    type: 'checkbox',
  },
  {
    kr: '',
    en: 'delete',
    type: 'delete',
  },
];

export const ALARM_STANDARD_SET = [
  {
    kr: '등록번호',
    en: 'id',
  },
  {
    kr: '센서명',
    en: 'name',
  },
];

export const ALARM_LIST = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '알람 종류',
    en: 'type',
    type: 'tag',
  },
  {
    kr: '레벨명',
    en: 'alarmCaseName',
    type: 'tag',
  },
  {
    kr: '설비명',
    en: 'dashboardName',
  },
  {
    kr: '내용',
    en: 'content',
  },
  {
    kr: '발생일',
    en: 'createdTime',
  },
  {
    kr: '상태',
    en: 'status',
    type: 'tag',
  },
  {
    kr: '',
    en: 'view',
    type: 'view',
  },
];

export const OPERATING_STATUS_SET = [
  {
    kr: '도메인명',
    en: 'dashboardName',
  },
  {
    kr: '가동 상태 대표 센서',
    en: 'targetSensorId',
    type: 'select',
  },
  {
    kr: '가동 범위',
    en: 'range',
    type: 'inputPop',
  },
  {
    kr: '가동 색상',
    en: 'activeColor',
    type: 'color',
  },
  {
    kr: '비가동 색상',
    en: 'inactiveColor',
    type: 'color',
  },
];

export const NOTICE = [
  {
    kr: 'No.',
    en: 'ReverseIndex',
  },
  {
    kr: '제목',
    en: 'title',
  },
  {
    kr: '공지 대상',
    en: 'target',
  },
  {
    kr: '등록일',
    en: 'createdAt',
  },
];
