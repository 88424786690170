/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import { getNoticeList } from 'apis/notice-rest';
import { queryKeys } from 'services/queryKeys';

export const useNotice = () => {
  return useQuery([queryKeys.NOTICE, 'LIST'], getNoticeList);
};

export const useMainNotice = () => {
  return useQuery(
    [queryKeys.NOTICE, 'MAIN'],
    () => getNoticeList({ size: 30, display: 'TRUE' }),
    {
      select: (data) => {
        return data?.contents;
      },
    },
  );
};
