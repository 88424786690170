/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import { getNoticeView } from 'apis/notice-rest';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/queryKeys';
import { styled } from 'styled-components';
import draftToHtml from 'draftjs-to-html';
import { colors } from 'constants/colors';

interface IProps {}
const NoticePanel: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get('id');

  const {
    data: boardData,
    error,
    isLoading,
  } = useQuery([queryKeys.NOTICE, id], () => getNoticeView({ id }), {
    enabled: !!id,
  });

  let html = '';
  if (boardData?.contents) {
    try {
      const rawContentState = JSON.parse(boardData.contents);
      const htmlString = draftToHtml(rawContentState);
      html = htmlString.replace(/\n/g, '<br/>');
    } catch (error) {
      html = boardData.contents;
    }
  }

  return (
    <Wrap>
      <BoardViewWrap>
        <BoardTitle>{boardData?.title}</BoardTitle>
        <BoardDate>{boardData?.createdAt}</BoardDate>
        <BoardContent dangerouslySetInnerHTML={{ __html: html }} />
      </BoardViewWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 18px 24px;
  margin-left: 254px;
  margin-top: 74px;
  justify-content: space-between;
  font-family: 'PretendardVariable';
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  border-radius: 16px;
  svg {
    fill: ${({ theme }) => theme.TEXT_PRIMARY};
  }
`;

const BoardViewWrap = styled.main`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  padding: 16px;
`;

const BoardTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
`;
const BoardDate = styled.div``;
const BoardContent = styled.div`
  line-height: 14px;
  margin-top: 20px;
`;

export default NoticePanel;
