import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteAccount,
  getAccountList,
  postAccount,
  putAccount,
} from 'apis/account-rest';
import { queryKeys } from 'services/queryKeys';
import { account } from 'types/account-T';

export const useAccount = () => {
  return useQuery([queryKeys.ACCOUNT], getAccountList);
};

export const useDeleteAccount = () => {
  return useMutation((accountId: number) => deleteAccount(accountId));
};

export const usePostAccount = () => {
  return useMutation((prms: account) => {
    const { loginId, password, name, email, phone, role } = prms;
    const cvtPrms = { loginId, password, name, email, phone, role };
    return postAccount(cvtPrms);
  });
};

export const usePatchAccount = () => {
  return useMutation((prms: account) => {
    const { id, password, name, email, phone, role } = prms;
    const cvtPrms = { password, name, email, phone, role };
    return putAccount(id!, cvtPrms);
  });
};
