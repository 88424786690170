import { subjectAlarm } from './enum';

export const ADD_UPDATE_DB_INFO = [
  {
    kr: 'DB 타입',
    en: 'typeId',
  },
  {
    kr: 'DB URL',
    en: 'databaseURL',
  },
  {
    kr: 'DB 포트',
    en: 'databasePort',
  },
  {
    kr: 'DB명',
    en: 'databaseName',
  },
  {
    kr: '계정',
    en: 'account',
  },
  {
    kr: '비밀번호(PW)',
    en: 'password',
  },
];

export const ADD_UPDATE_ENTERPRISE = {
  img: {
    kr: '회사 로고',
    en: 'logo',
  },
  input: [
    {
      kr: '기업명',
      en: 'name',
    },
    {
      kr: '주소',
      en: 'address',
    },
    {
      kr: '사업자 번호',
      en: 'businessNumber',
    },
    {
      kr: '전화번호',
      en: 'phone',
    },
  ],
  inputNum: [
    {
      kr: '계정 보유 한도',
      en: 'accountLimit',
    },
    {
      kr: '대시보드 보유 한도',
      en: 'dashboardLimit',
    },
  ],
  select: {
    kr: '서비스 이용 상태',
    en: 'serviceStatus',
  },
};

export const ADD_ACCOUNT = [
  {
    kr: '계정',
    en: 'loginId',
  },
  {
    kr: '패스워드',
    en: 'password',
  },
  {
    kr: '패스워드 확인',
    en: 'isSamePassword',
  },
  {
    kr: '이름',
    en: 'name',
  },
  {
    kr: '전화번호',
    en: 'phone',
  },
  {
    kr: '이메일',
    en: 'email',
  },
  {
    kr: '접속 시간',
    en: 'monitoring',
  },
];

export const UPDATE_ACCOUT = [
  {
    kr: '계정',
    en: 'loginId',
  },
  {
    kr: '새로운 패스워드',
    en: 'password',
  },
  {
    kr: '패스워드 확인',
    en: 'isSamePassword',
  },
  {
    kr: '이름',
    en: 'name',
  },
  {
    kr: '전화번호',
    en: 'phone',
  },
  {
    kr: '이메일',
    en: 'email',
  },
  {
    kr: '접속 시간',
    en: 'monitoring',
  },
];

export const ADD_UPDATE_DASHBOARD = [
  {
    kr: '기업',
    en: '',
  },
  {
    kr: '이름',
    en: '',
  },
  {
    kr: '도메인',
    en: '',
  },
];

export const DASHBOARD_OPTION = [];

export const UPDATE_EQUIPMENT = [
  {
    kr: '이름',
    en: 'name',
  },
  {
    kr: '타입',
    en: 'type',
  },
  {
    kr: '설명',
    en: 'description',
  },
];

export const ACCOUNT_DESC = [
  {
    kr: '권한',
    en: 'admin',
  },
  {
    kr: '계정',
    en: 'username',
  },
  {
    kr: '이름',
    en: 'fullname',
  },
  {
    kr: '마지막 로그인',
    en: 'lastLoginTime',
  },
];

// 표현 방식 및 스크립트 생성
export const ADD_SENSOR = [
  {
    kr: '장치',
    en: 'deviceId',
  },
  {
    kr: '데이터 종류',
    en: 'sensorTypeId',
  },
  {
    kr: '표현 방식',
    en: 'displayType',
  },
  {
    kr: '명칭',
    en: 'name',
  },
  {
    kr: '단위',
    en: 'unit',
  },
  {
    kr: '설명',
    en: 'description',
  },
];

export const PUT_SENSOR = [
  {
    kr: '장치',
    en: 'deviceId',
  },
  {
    kr: '데이터 종류',
    en: 'sensorTypeId',
  },
  {
    kr: '표현 방식',
    en: 'displayType',
  },
  {
    kr: '스크립트명',
    en: 'script',
  },
  {
    kr: '명칭',
    en: 'name',
  },
  {
    kr: '단위',
    en: 'unit',
  },
  {
    kr: '설명',
    en: 'description',
  },
];

export const DETAIL_SENSOR_INFO = [
  {
    kr: '장치',
    en: 'deviceName',
  },
  {
    kr: '데이터 종류',
    en: 'sensorTypeName',
  },
  {
    kr: '표현 방식',
    en: 'displayName',
  },
  {
    kr: '명칭',
    en: 'name',
  },
  {
    kr: '단위',
    en: 'unit',
  },
  {
    kr: '스크립트명',
    en: 'script',
  },
  {
    kr: '설명',
    en: 'description',
  },
];

export const ALARM_SET_MENU_OPT = [
  {
    kr: '알람 규칙 설정',
    en: subjectAlarm.CASE_SETTING,
  },
  {
    kr: '센서 알람 기준 설정',
    en: subjectAlarm.STANDARD_SETTING,
  },
  // {
  //   kr: '가동 상태 설정',
  //   en: subjectAlarm.OPERATING_STATUS_SETTING,
  // },
];
