/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from 'constants/colors';
import { lighten } from 'polished';
import React from 'react';
import { ALARM_SET_MENU_OPT } from 'services/modal-text';
import styled from 'styled-components';
interface IProps {
  selectedMenu: string;
  setSelectedMenu: any;
}
const AlarmMenuList: React.FC<IProps> = ({ selectedMenu, setSelectedMenu }) => {
  return (
    <MenuListWrap>
      <span>메뉴 선택</span>
      {ALARM_SET_MENU_OPT.map((item) => (
        <MenuItem
          key={item.en}
          onClick={() => setSelectedMenu(item.en)}
          $isSelected={selectedMenu === item.en}
        >
          <span>{item.kr}</span>
        </MenuItem>
      ))}
    </MenuListWrap>
  );
};

const MenuListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 24px;
  padding-right: 32px;
  border-right: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}22;

  > span {
    text-align: center;
    color: ${({ theme }) => theme.TEXT_PRIMARY}CC;
    font-size: 16px;
  }
`;

const MenuItem = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${({ $isSelected, theme }) =>
    $isSelected ? lighten(0.1, theme.PRIMARY) : theme.TEXT_PRIMARY + 'CC'};
  font-weight: ${({ $isSelected }) => ($isSelected ? 'bold' : 'normal')};

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.PRIMARY)};
    cursor: pointer;
  }
`;

export default AlarmMenuList;
