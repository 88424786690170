import { colors } from 'constants/colors';
import { css, keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-64px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const getDefaultWrap = css`
  display: flex;
  flex-direction: column;
  width: 480px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  box-shadow: 0 0 15px 0 ${({ theme }) => theme.TEXT_PRIMARY}11;
  border-radius: 8px;
  padding: 24px;
  gap: 24px;
  animation: ${slideDown} 300ms ease-out forwards;
`;
