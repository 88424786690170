/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
interface IProps {
  rangeData: any;
  setRangeData: any;
  keyIdx: string;
}
const InfinityTooltip: React.FC<IProps> = ({
  rangeData,
  setRangeData,
  keyIdx: key,
}) => {
  const leftVal = rangeData[key][0];
  const rightVal = rangeData[key][1];

  const minusInfinity = '-Infinity';
  const infinity = 'Infinity';

  const initBtn = leftVal !== minusInfinity && rightVal !== infinity;
  const isMinusInfinity = leftVal === minusInfinity;

  return (
    <Wrap>
      <span>무한대 설정</span>
      <div>
        {initBtn && (
          <>
            <button
              onClick={() => {
                setRangeData({
                  ...rangeData,
                  [key]: ['-Infinity', rightVal],
                });
              }}
            >
              <span>- ∞</span>
            </button>
            <button
              onClick={() => {
                setRangeData({
                  ...rangeData,
                  [key]: [leftVal, 'Infinity'],
                });
              }}
            >
              <span>+ ∞</span>
            </button>
          </>
        )}
      </div>
      {!initBtn && (
        <ClearBtn
          onClick={() => {
            const initVal = isMinusInfinity ? ['', rightVal] : [leftVal, ''];
            setRangeData({
              ...rangeData,
              [key]: initVal,
            });
          }}
        >
          <span>{isMinusInfinity ? '- ∞' : '+ ∞'}</span>
          <span> 해제</span>
        </ClearBtn>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 108px;
  > span {
    text-align: center;
    font-size: 12px;
    color: ${colors.WHITE};
    font-weight: 700;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${colors.NEUTRAl_300};
    > button {
      width: 50px;
      color: ${colors.WHITE};
      font-size: 15px;
      border: none;
      background-color: transparent;

      > span {
        &:hover {
          color: ${colors.BLUE_LIGHT};
          font-weight: 700;
        }
      }
    }
  }
`;

const ClearBtn = styled.button`
  width: 100%;
  font-size: 14px;
  color: ${colors.WHITE};
  border: none;
  background-color: transparent;
  text-align: center;
  &:hover {
    color: ${colors.BLUE_LIGHT};
    font-weight: 700;
  }

  > span:nth-child(2) {
    font-size: 12px;
  }
`;

export default InfinityTooltip;
