import React, { SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCompanyList } from 'apis/account-rest';
import { queryKeys } from 'services/queryKeys';
import { companyInfo, companyList } from 'types/account-T';
import { getOneCompany } from 'apis/dashboard-rest';

export const useEnterprise = (
  setTable?: React.Dispatch<SetStateAction<companyInfo[]>>,
) => {
  return useQuery([queryKeys.ENTERPRISE], getCompanyList, {
    onSuccess: (data: companyList[]) => setTable?.(data),
  });
};

export const useOneEnterprise = (companyId: number) => {
  return useQuery([queryKeys.ENTERPRISE_ONE, companyId], () =>
    getOneCompany(companyId),
  );
};
