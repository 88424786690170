/* eslint-disable @typescript-eslint/no-explicit-any */
import CommonTable from 'components/TablePanel/CommonTable';
import React, { SetStateAction, useEffect, useState } from 'react';
import { subjectAlarm } from 'services/enum';
import { findFirstDashboard, findHasDashboardIds } from 'services/miscFunc';
import { OPERATING_STATUS_SET } from 'services/table-text';
import styled from 'styled-components';
interface IProps {
  data: any;
  dashboardId: number;
  domainList: any;
  setData: any;
  setDashboardId: React.Dispatch<SetStateAction<number>>;
}
const AlarmOperingSetting: React.FC<IProps> = ({
  data,
  dashboardId,
  domainList,
  setData,
  setDashboardId,
}) => {
  const [dashboardList, setDashboardList] = useState<any>([]);

  // useEffect(() => {
  //   const isValidDashboard = dashboardId === -1 && domainList.length > 0;
  //   if (isValidDashboard) {
  //     const firstDashboardData = findFirstDashboard(domainList);
  //     if (firstDashboardData && firstDashboardData.dashboard) {
  //       const { id } = firstDashboardData.dashboard;
  //       setDashboardId(id);
  //     }
  //   }
  //   findHasDashboardIds(domainList, setDashboardList);
  // }, [domainList.length]);

  const equipmentDomainName =
    dashboardList.find((item: any) => item.id === dashboardId)?.domainName ||
    '';

  return (
    <>
      <DomainWrap>
        <span>도메인명</span>
        <span>{equipmentDomainName}</span>
      </DomainWrap>
      <EquipSelect>
        <span>설비명</span>
        <select
          name="domain"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setDashboardId(Number(e.target.value))
          }
          value={dashboardId}
        >
          {dashboardList.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </EquipSelect>
      <CaseTableWrap>
        <CommonTable
          tableHeaderData={OPERATING_STATUS_SET}
          tableData={data}
          setTableData={setData}
          subjectType={subjectAlarm.OPERATING_STATUS_SETTING}
        />
      </CaseTableWrap>
    </>
  );
};

const DomainWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > span:nth-child(1) {
    width: 80px;
  }
`;

const EquipSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > span:nth-child(1) {
    width: 80px;
  }

  > select {
    width: 280px;
    text-align: center;
    padding: 5px 4px;
    border-radius: 4px;
    outline: 0;
  }
`;

const CaseTableWrap = styled.div`
  width: 100%;
  max-height: 360px;
  overflow: auto;
  input {
    vertical-align: middle;
    border-radius: 4px;
  }
  input[type='text'],
  select {
    text-align: center;
    height: 26px;
    outline: 0;
  }
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
`;

export default AlarmOperingSetting;
