import Modal from 'components/common/Modal';
import React, { useState, FormEvent } from 'react';
import { styled } from 'styled-components';
import { getDefaultWrap } from './common/modalCss';
import ModalButton from './common/ModalButton';
import { colors } from 'constants/colors';
import { pwCheck } from 'apis/account-rest';
import { useAppSelect } from 'store/configureStore.hooks';
import { useNavigate } from 'react-router-dom';
import routePath from 'constants/route';
interface IProps {
  onClose: () => void;
}

const CheckedPwModal: React.FC<IProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const { role, id } = useAppSelect((state) => state.accountInfo.info);
  const [alarmMessage, setAlarmMessage] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const prms = {
      id,
      password,
    };

    const result = await pwCheck(prms);

    if (!result.status) {
      setAlarmMessage('비밀번호가 일치하지 않습니다.');
      return;
    }
    navigate(routePath.ACCOUNT_MANAGE);
  };

  const authorizationText =
    role === 'ADMIN'
      ? '관리자'
      : role === 'MASTER'
      ? '마스터'
      : role === 'USER'
      ? '유저'
      : '';

  return (
    <Modal>
      <Wrap onSubmit={handleSubmit}>
        <span>{authorizationText} 비밀번호를 입력해주세요.</span>
        <MyInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p>{alarmMessage}</p>
        <ModalButton btnText="확인" btnWidth={120} onClose={onClose} />
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.form`
  ${getDefaultWrap};
  width: 320px;
  align-items: center;

  > p {
    color: ${colors.ALERT};
    font-size: 12px;
  }
`;

const MyInput = styled.input`
  width: 80%;
  border: 2px solid rgb(244, 244, 244);
  padding: 4px 12px;
  font-size: 14px;

  &:focus {
    border: 2px solid ${colors.BLUE_BASE};
    transition: 0.7s;
  }

  &::placeholder {
    font-size: 12px;
  }
`;

export default CheckedPwModal;
