import React from 'react';
import { Typography } from '@mui/material';
import { useOneEnterprise } from 'apiHooks/useEnterprise';
import { useCoordinates, useWeather } from 'apiHooks/useWeather';
import CurrentWeather from 'components/CurrentWeather/CurrentWeather';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { useAppSelect } from 'store/configureStore.hooks';
import styled from 'styled-components';
const initCoordinates = {
  x: '',
  y: '',
};
const Weather = () => {
  const { companyId } = useAppSelect((state) => state.accountInfo.info);
  const { data: companyInfo } = useOneEnterprise(companyId);

  const { data: coordinates = initCoordinates } = useCoordinates(
    companyInfo?.address || '',
  );
  const { data: curWeatherInfo, isLoading: curIsLoading } = useWeather({
    x: coordinates?.x || '',
    y: coordinates?.y || '',
    isLong: false,
  });

  // const { data: weekWeatherInfo, isLoading: weekIsLoading } = useWeather({
  //   x: coordinates?.x || '',
  //   y: coordinates?.y || '',
  //   isLong: true,
  // });
  // console.log(weekWeatherInfo);
  const isEnteredAddress =
    coordinates.x.length === 0 && coordinates.y.length === 0;
  return (
    <Wrap>
      {isEnteredAddress && (
        <Typography fontSize={13}>
          날씨 정보는 주소 등록 후 이용해주세요.
        </Typography>
      )}
      {curIsLoading && !isEnteredAddress && <LoadingSpinner size={18} />}
      {!curIsLoading && <CurrentWeather data={curWeatherInfo} />}
      {/* 05/23, 05/24, 05/25 */}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  padding: 12px;
`;

export default Weather;
