/* eslint-disable @typescript-eslint/no-explicit-any */
import CommonTable from 'components/TablePanel/CommonTable';
import LoadingSpinner from 'components/common/LoadingSpinner';
import React, { useEffect, useRef } from 'react';
import { ALARM_STANDARD_SET } from 'services/table-text';
import styled from 'styled-components';
import { sensor } from 'types/dashboard-T';
import { tableHeaderType } from 'types/table-T';
import editImg from 'assets/img/edit.png';
import { findFirstDashboard } from 'services/miscFunc';
import { subjectAlarm } from 'services/enum';
import { useAlarmCase } from 'apiHooks/useAlarm';
import { alarmCaseType } from 'types/alarm-T';

interface IProps {
  tableData: sensor[];
  domainList: any[];
  dashboardId: number;
  isLoading: boolean;
  setDashboardId: React.Dispatch<React.SetStateAction<number>>;
  setTableData: React.Dispatch<React.SetStateAction<sensor[]>>;
}

const AlarmStandardSetting: React.FC<IProps> = ({
  dashboardId,
  setDashboardId,
  domainList,
  tableData,
  isLoading,
  setTableData,
}) => {
  const { data: alarmCaseList = [] } = useAlarmCase();
  const tableWrapRef = useRef<HTMLDivElement>(null);

  const alarmRulesData = alarmCaseList
    .map((item: alarmCaseType) => {
      return {
        kr: item.caseName,
        en: `caseName${item.id}`,
        type: 'inputPop',
        priority: item.priority,
      };
    })
    .sort((a: any, b: any) => a.priority - b.priority);

  const tableHeaderData: tableHeaderType[] = [
    ...ALARM_STANDARD_SET,
    ...alarmRulesData,
  ];

  // 마지막에 확인 시, 필요한 데이터만 정렬하여 보내야 함

  useEffect(() => {
    const isValidDashboard = dashboardId === -1 && domainList.length > 0;
    if (isValidDashboard) {
      const firstDashboardData = findFirstDashboard(domainList);
      if (firstDashboardData && firstDashboardData.dashboard) {
        const { id } = firstDashboardData.dashboard;
        setDashboardId(id);
      }
    }
    // findHasDashboardNames(domainList, setDashboardList);
  }, [domainList.length]);

  const equipmentDomainName =
    domainList.find((item: any) => item.id === dashboardId)?.domainName || '';

  const filteredUnHideData = tableData.filter((item) => !item.hide);

  return (
    <>
      <DomainWrap>
        <span>도메인명</span>
        <span>{equipmentDomainName}</span>
      </DomainWrap>
      <EquipSelect>
        <span>설비명</span>
        <select
          name="domain"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setDashboardId(Number(e.target.value))
          }
          value={dashboardId}
        >
          {domainList.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </EquipSelect>
      {isLoading && <LoadingSpinner size={30} />}
      {!isLoading && (
        <StandardTableWrap $img={editImg} ref={tableWrapRef}>
          <CommonTable
            tableHeaderData={tableHeaderData}
            tableData={filteredUnHideData}
            subjectType={subjectAlarm.STANDARD_SETTING}
            setTableData={setTableData}
          />
        </StandardTableWrap>
      )}
    </>
  );
};

const DomainWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > span:nth-child(1) {
    width: 80px;
  }
`;

const EquipSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > span:nth-child(1) {
    width: 80px;
  }

  > select {
    width: 280px;
    text-align: center;
    padding: 5px 4px;
    border-radius: 4px;
    outline: 0;
  }
`;

const StandardTableWrap = styled.div<{ $img: any; ref: any }>`
  width: 100%;
  max-height: 360px;
  overflow: auto;
  border-radius: 10px 10px 0 0;

  th:nth-child(1) {
    width: 150px;
  }
  th:nth-child(2) {
    width: 350px;
  }
  th {
    min-width: 150px;
  }

  td input {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 15px;
    margin-right: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  input:not(input[type='number']) {
    vertical-align: middle;
    border-radius: 4px;
    background-image: url(${({ $img }) => $img});
    background-position: right 7px center;
    background-repeat: no-repeat;
    background-size: 13px;
    border: 1px solid #2e3238;
    height: 24px;
    box-sizing: border-box;
    outline: none;
  }
`;

export default AlarmStandardSetting;
