import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { domainT } from 'types/dashboard-T';
import DashboardItem from 'components/DashboardItem/DashboardItem';
import { useDispatch } from 'react-redux';
import {
  setDashboardId as reduxDashboardId,
  setCollapsedIds,
} from 'store/modules/pageInfo';
import { useAppSelect } from 'store/configureStore.hooks';
import { useNavigate } from 'react-router-dom';
import routePath from 'constants/route';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useNav } from 'apiHooks/useNav';
interface IProps {
  onRefreshPanel?: (dashboardId: number) => void;
}

const Nav: React.FC<IProps> = ({ onRefreshPanel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashboardId } = useAppSelect((state) => state.pageInfo);
  const { collapsedIds } = useAppSelect((state) => state.pageInfo);

  const { data, isLoading, refetch } = useNav();

  // useEffect(() => {
  //   if (dashboardId === -1 && data?.domains.length > 0) {
  //     const firstDashboardData = findFirstDashboard(data.domains);
  //     if (firstDashboardData) {
  //       if (firstDashboardData.dashboard) {
  //         const { id } = firstDashboardData.dashboard;
  //         handleClickItem(id);
  //       }
  //     }
  //   }
  // }, [dashboardId, data]);

  const toggleCollapseAll = () => {
    if (collapsedIds.length !== 0) {
      dispatch(setCollapsedIds([]));
    } else {
      dispatch(
        setCollapsedIds(filteredNavData.map((child: domainT) => child.id)),
      );
    }
  };

  const handleClickItem = (id: number) => {
    dispatch(reduxDashboardId(id));
    onRefreshPanel && onRefreshPanel(dashboardId);
    navigate(routePath.DASHBOARD);
  };

  if (!data) return <Wrap />;
  const navData = data.domains;
  const filteredNavData = navData.filter((child: domainT) => !child.dashboard);

  return (
    <Wrap>
      <HeaderWrap>
        <span>{data.company.name}</span>
      </HeaderWrap>
      {filteredNavData?.length ? (
        <CollaspseButton
          onClick={toggleCollapseAll}
          $isAllShow={collapsedIds.length !== 0}
        >
          <UnfoldMoreIcon />
          {collapsedIds.length !== 0 ? '모든 메뉴 펼치기' : '모든 메뉴 접기'}
        </CollaspseButton>
      ) : (
        ''
      )}
      <DomainWrap>
        {navData.map((child: domainT) => (
          <DashboardItem
            key={child.id}
            data={child}
            depth={0}
            clickedItem={dashboardId}
            onClickItem={handleClickItem}
          />
        ))}
      </DomainWrap>
    </Wrap>
  );
};

const Wrap = styled.nav`
  position: fixed;
  top: 66px;
  width: 230px;
  height: calc(96% - 66px);
  margin-top: 8px;
  background: ${({ theme }) => theme.CARD_BG_PRIMARY};
  border-radius: 12px;
  box-shadow: 0 0 15px 0 ${({ theme }) => theme.TEXT_PRIMARY}11;
  padding: 24px 16px;
  z-index: 10;

  &:hover {
    width: 340px;
    transition: 0.1s ease-in;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  font-family: 'PretendardVariable';
  font-size: 14px;
  padding-left: 4px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DomainWrap = styled.div`
  margin-top: 18px;
  /* border-left: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}3E; */
  display: flex;
  flex-direction: column;
  color: hsla(0, 0%, 100%, 0.8);
  /* gap: 8px; */
`;

const CollaspseButton = styled.button<{ $isAllShow: boolean }>`
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 150px;
  margin-top: 15px;
  font-size: 13px;
  border: none;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: ${({ theme, $isAllShow }) =>
    !$isAllShow ? theme.BUTTON_SECONDARY : theme.BUTTON_THIRD};
  color: ${({ theme, $isAllShow }) =>
    !$isAllShow ? theme.TEXT_PRIMARY : theme.WHITE};
  font-size: 12px;
  padding: 5px 18px;
  svg {
    font-size: 14px;
  }
`;

export default Nav;
