/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useAlarmRules,
  useMutationAlarmRules,
  useMutationDeleteRules,
} from 'apiHooks/useAlarm';
import { useNav } from 'apiHooks/useNav';
import { apiCompleted } from 'apis/common';
import AlarmStandardSetting from 'components/AlarmStandardSetting/AlarmStandardSetting';
import SetAlarmLayout from 'layout/SetAlarmLayout';
import React, { useEffect, useState } from 'react';
import { INIT_ALARM_STANDARD } from 'services/init-data';
import { cvtalarmRules, findHasDashboardNames } from 'services/miscFunc';
import { sensor } from 'types/dashboard-T';
import SettingsIcon from '@mui/icons-material/Settings';
import * as _ from 'lodash';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import ConfirmModal from './ConfirmModal';
import useOnModal from 'hooks/useOnModal';
import { useStandardSensors } from 'apiHooks/useDashboard';

interface IProps {
  selectedMenu: string;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
}
const SetAlarmStandard: React.FC<IProps> = ({
  selectedMenu,
  setSelectedMenu,
  onClose,
}) => {
  const [hasDashboardList, setHasDashboardList] = useState([]);
  const [dashboardId, setDashboardId] = useState<number>(-1);
  const [tableData, setTableData] = useState<sensor[]>(
    INIT_ALARM_STANDARD.sensors,
  );

  const { data: rulesData = [], refetch: alarmRulesRefetch } =
    useAlarmRules(dashboardId);
  const { isLoading, refetch: sensorsRefetch } = useStandardSensors({
    dashboardId,
    setTableData,
    rulesData,
  });
  const { mutateAsync } = useMutationAlarmRules();
  const { mutateAsync: mutateDeleteAsync } = useMutationDeleteRules();
  const { data: domainList } = useNav();

  const { onDeleteModal, onClickDeleteModal } = useOnModal();

  useEffect(() => {
    if (domainList?.domains) {
      findHasDashboardNames(domainList?.domains, setHasDashboardList);
    }
  }, [domainList?.domains]);

  const handleClickSave = async () => {
    const cvtRulesData = cvtalarmRules(tableData);
    const prms = { dashboardId, prms: cvtRulesData };
    const res = await mutateAsync(prms);
    if (res?.success) {
      apiCompleted();
      alarmRulesRefetch();
    }
  };

  const handleClickDeleteAllVal = async () => {
    const rulesIdData = rulesData.map((rule: any) => rule.alarmRuleId);
    const prms = { dashboardId, prms: rulesIdData };
    const res = await mutateDeleteAsync(prms);

    if (res?.success) {
      await alarmRulesRefetch();
      apiCompleted();
      sensorsRefetch();
      onClickDeleteModal();
    }
  };

  const addModalBtn = (
    <AddModalBtn onClick={onClickDeleteModal}>
      <SetIcon sx={{ width: '16px' }} />
      <span>설정 초기화</span>
    </AddModalBtn>
  );

  return (
    <>
      <SetAlarmLayout
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        addModalBtn={addModalBtn}
        main={
          <AlarmStandardSetting
            isLoading={isLoading}
            dashboardId={dashboardId}
            tableData={tableData}
            domainList={hasDashboardList}
            setDashboardId={setDashboardId}
            setTableData={setTableData}
          />
        }
        onClose={onClose}
        onClickSave={handleClickSave}
      />
      {onDeleteModal && (
        <ConfirmModal
          text={`설비에 설정되어있는 모든 값을 초기화하시겠습니까?\n확인 클릭 시, 기준값을 이전으로 되돌릴 수 없습니다.`}
          onClick={handleClickDeleteAllVal}
          onClose={onClickDeleteModal}
        />
      )}
    </>
  );
};

const AddModalBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 140px;
  padding: 4px 24px;
  > span {
    color: ${({ theme }) => theme.WHITE};
    font-weight: 500;
    padding-bottom: 1px;
  }
  color: ${({ theme }) => theme.WHITE};
  background: ${({ theme }) => theme.PRIMARY};
  border-radius: 6px;
  border: 0;
  outline: 0;
`;

const SetIcon = styled(SettingsIcon)`
  color: ${({ theme }) => theme.WHITE};
`;
export default SetAlarmStandard;
