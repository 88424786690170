/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { colors } from 'constants/colors';
import { css, styled } from 'styled-components';
import { rowT, tableHeaderType } from 'types/table-T';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Tag from 'components/Tag/Tag';
import { Button, Popover, Tooltip, Typography } from '@mui/material';
import * as _ from 'lodash';
import { ReactComponent as DeleteSVG } from 'assets/img/delete.svg';
import PopoverContent from 'components/PopoverContent/PopoverContent';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { subjectAlarm } from 'services/enum';
import AlarmCaseDesc from 'components/AlarmCaseDesc/AlarmCaseDesc';
import { useDispatch } from 'react-redux';
import { isEnteredPositiveNumber } from 'services/miscFunc';
interface IProps {
  tableHeaderData: tableHeaderType[];
  tableData: rowT[];
  isDraggable?: boolean;
  subjectType: string;
  tooltipItem?: { title: string; node: React.ReactNode };
  setTableData?: React.Dispatch<React.SetStateAction<any>>;
  onClickPlusBtn?: (data?: any) => void;
  onClickDeleteBtn?: (data: any) => void;
}
const CommonTable: React.FC<IProps> = ({
  tableHeaderData,
  tableData,
  isDraggable,
  subjectType,
  tooltipItem,
  setTableData,
  onClickPlusBtn,
  onClickDeleteBtn,
}) => {
  const [curPage, setCurPage] = useState(1);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<any>({});

  const dispatch = useDispatch();

  const handleClickInputPop = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    inputId: string,
  ) => {
    setPopoverAnchorEl({ ...popoverAnchorEl, [inputId]: e.currentTarget });
  };

  const handleClosePopover = (inputId: string) => {
    setPopoverAnchorEl({ ...popoverAnchorEl, [inputId]: null });
  };

  const onChangeInput = (
    value: any,
    key: string,
    idx: number,
    popoverClose?: any,
  ) => {
    tableData[idx] = { ...tableData[idx], [key]: value };
    setTableData?.(_.cloneDeep(tableData));
    popoverClose && popoverClose();
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = [...tableData];
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);
    const cvtData = items.map((item, idx) => ({ ...item, priority: idx + 1 }));
    setTableData?.(_.cloneDeep(cvtData));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="customDndMode">
        {(provided) => (
          <Table>
            <HeaderWrap>
              <tr>
                {tableHeaderData.map((data: tableHeaderType) => (
                  <Th key={data.kr}>
                    {data.kr}
                    {data.en === tooltipItem?.title && <AlarmCaseDesc />}
                  </Th>
                ))}
              </tr>
            </HeaderWrap>
            <BodyWrap
              className="customDndMode"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tableData.map((data: any, idx: number) => (
                <Draggable
                  draggableId={`${idx}`}
                  key={`${idx}`}
                  index={idx}
                  isDragDisabled={!isDraggable}
                >
                  {(provided) => (
                    <TableTr
                      $idx={idx}
                      $type={subjectType}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {tableHeaderData.map((hData: tableHeaderType) => {
                        const keys = Object.keys(data);
                        const hasDataKey = keys.includes(hData.en);
                        const keyData = data[hData.en];
                        0;
                        const hasType = hData.type;
                        const index = (curPage - 1) * 10 + (idx + 1);

                        if (!hasType) {
                          if (hData.en === 'Index') {
                            return <Td key={data.id}>{index}</Td>;
                          }

                          if (hasDataKey) {
                            return <Td key={hData.kr}>{keyData}</Td>;
                          }
                        }

                        if (hasType) {
                          if (hData.type === 'number') {
                            return (
                              <Td key={hData.kr}>
                                <input
                                  name={hData.en}
                                  type="text"
                                  value={keyData === 0 ? '' : keyData}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    if (
                                      isEnteredPositiveNumber(e.target.value)
                                    ) {
                                      onChangeInput(
                                        Number(e.target.value),
                                        hData.en,
                                        idx,
                                      );
                                    }
                                  }}
                                />
                              </Td>
                            );
                          }

                          const inputTypes = ['input', 'color'];

                          if (inputTypes.includes(hData.type!)) {
                            return (
                              <Td key={hData.kr}>
                                <input
                                  name={hData.en}
                                  type={hData.type}
                                  value={keyData}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    onChangeInput(e.target.value, hData.en, idx)
                                  }
                                />
                              </Td>
                            );
                          }

                          if (hData.type === 'checkbox') {
                            return (
                              <Td key={hData.kr}>
                                <input
                                  type="checkbox"
                                  checked={keyData}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    onChangeInput(
                                      e.target.checked,
                                      hData.en,
                                      idx,
                                    )
                                  }
                                />
                              </Td>
                            );
                          }

                          if (hData.type === 'select') {
                            return (
                              <Td key={hData.kr}>
                                <select
                                  value={keyData}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                  ) =>
                                    onChangeInput(e.target.value, hData.en, idx)
                                  }
                                >
                                  <option value="test1">test1</option>
                                  <option value="test2">test2</option>
                                </select>
                              </Td>
                            );
                          }

                          if (hData.type === 'inputPop') {
                            const popOverId = `inputPopover-${hData.kr}-${idx}`;

                            const rangeValue =
                              keyData?.length > 0
                                ? keyData.map((e: any) => {
                                    const isMinusInfinity =
                                      e[0] === '-Infinity';
                                    const isInfinity = e[1] === 'Infinity';
                                    const leftVal = isMinusInfinity
                                      ? `${e[1]}↓`
                                      : e[0];
                                    const rightVal = isInfinity
                                      ? `${e[0]}↑`
                                      : e[1];

                                    return isMinusInfinity
                                      ? leftVal
                                      : isInfinity
                                      ? rightVal
                                      : ` ${leftVal}~${rightVal}`;
                                  })
                                : '미설정';
                            // 데이터는 밑으로 내려야함
                            return (
                              <Td key={hData.kr}>
                                <PopInput
                                  type="text"
                                  value={rangeValue}
                                  onClick={(e) => {
                                    handleClickInputPop(e, popOverId);
                                  }}
                                  readOnly
                                />
                                <InputPopover
                                  id={popOverId}
                                  open={Boolean(popoverAnchorEl[popOverId])}
                                  anchorEl={popoverAnchorEl[popOverId]}
                                  onClose={() => handleClosePopover(popOverId)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transitionDuration={{ enter: 170, exit: 170 }}
                                >
                                  <PopoverContent
                                    onChangeInput={onChangeInput}
                                    data={data}
                                    hData={hData}
                                    idx={idx}
                                    popoverClose={() =>
                                      handleClosePopover(popOverId)
                                    }
                                  />
                                </InputPopover>
                              </Td>
                            );
                          }

                          if (hData.type === 'view') {
                            return (
                              <Td key={hData.kr}>
                                <Tooltip
                                  placement="top"
                                  title={
                                    <Typography fontSize={14}>
                                      상세 보기
                                    </Typography>
                                  }
                                >
                                  <ViewBtn
                                    onClick={() => onClickPlusBtn?.(data)}
                                  />
                                </Tooltip>
                              </Td>
                            );
                          }

                          if (hData.type === 'delete') {
                            return (
                              <Td key={hData.kr}>
                                <DeleteBtn
                                  onClick={() => onClickDeleteBtn?.(data)}
                                >
                                  <DeleteIcon />
                                </DeleteBtn>
                              </Td>
                            );
                          }

                          if (hData.type === 'tag') {
                            return (
                              <Td key={hData.kr}>
                                <Tag
                                  text={keyData}
                                  color={
                                    hData.en === 'alarmCaseName' &&
                                    data?.alarmCaseColor
                                  }
                                />
                              </Td>
                            );
                          }
                        }
                      })}
                    </TableTr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </BodyWrap>
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const tableCss = (type: string) => {
  if (type === subjectAlarm.CASE_SETTING) {
    return css`
      > td > input {
        padding: 2px;
        border-radius: 4px;
      }

      > td > input[name='periodMinute'] {
        width: 70px;
      }

      > td > input[type='checkbox'] {
        width: 18px;
        height: 18px;
      }
    `;
  }

  if (type === subjectAlarm.STANDARD_SETTING) {
    return css``;
  }

  if (type === subjectAlarm.OPERATING_STATUS_SETTING) {
    return css``;
  }
};

const tdOptionCss = css`
  display: flex;
  justify-content: center;
  gap: 18px;
  align-items: center;
`;

const Table = styled.table`
  min-width: 100%;
  max-height: 100%;
  background-color: ${colors.BOX_BG};
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  /* height: 100%; */
`;

const HeaderWrap = styled.thead`
  position: sticky;
  background: ${({ theme }) => theme.TABLE_HEADER_BG};
  top: 0;
`;

const BodyWrap = styled.tbody`
  // width: 100%;

  > tr {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
`;

const TableTr = styled.tr<{ $idx: number; $type: string }>`
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  ${({ $type }) => tableCss($type)};

  ${({ $idx, theme }) => css`
    background-color: ${$idx % 2 === 0
      ? theme.TABLE_TR_COLOR1
      : theme.TABLE_TR_COLOR2};
  `}
  > tr {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
`;

const Th = styled.th`
  padding: 12px 4px;
  font-size: 15px;
  font-family: 'PretendardVariable';
  font-weight: 600;
  color: ${({ theme }) => theme.TABLE_TH_COLOR};
`;

const Td = styled.td<{ $option?: boolean }>`
  text-align: center;
  padding: 8px 3px;
  vertical-align: middle;
  font-size: 14px;
  /* color: hsla(0, 0%, 100%, 0.7); */
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  input {
    text-align: center;
  }
  ${({ $option }) => $option && tdOptionCss};
`;

const OptionBtn = styled.button`
  border: none;
  font-size: 13px;
  font-family: 'Pretendard_R';
  background-color: ${colors.NEUTRAl_50};
  border-radius: 2px;

  &:hover {
    background-color: rgb(91, 155, 213);
    color: ${colors.WHITE};
    transition: 0.15s;
  }
`;

const DeleteBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
`;

const ViewBtn = styled(ZoomInIcon)`
  cursor: pointer;
`;

const InputPopover = styled(Popover)`
  .MuiPaper-root {
    background: #35374b;
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(10, 6, 2, 0.2);
    border-radius: 24px;
    width: 290px;
    height: 253px;
  }
`;

const DeleteIcon = styled(DeleteSVG)`
  fill: ${({ theme }) => theme.TEXT_PRIMARY};
  width: 16px;
  height: 16px;
`;

const PopInput = styled.input`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4px;
  letter-spacing: 0.25px;
  cursor: pointer;
`;

export default CommonTable;
