/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GlobalCss from './services/GlobalCss';
import routePath from './constants/route';
import { styled } from 'styled-components';
import { colors } from 'constants/colors';
import { useAppSelect } from 'store/configureStore.hooks';
import ErrorModal from 'components/ModalContents/ErrorModal';
import { useDispatch } from 'react-redux';
import CompleteModal from 'components/ModalContents/CompleteModal';
import { setResetConfirm } from 'store/modules/confirm';
import './App.css';
import SSEContainer from 'components/common/SSEContainer';
import NoticeList from 'pages/NoticeList';
import NoticeView from 'pages/NoticeView';

const Login = lazy(() => import('pages/Login'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Main = lazy(() => import('pages/Main'));
const NotFound = lazy(() => import('pages/NotFound'));
const AccountManage = lazy(() => import('pages/AccountManage'));

const App = () => {
  return (
    <Wrap>
      <SSEContainer>
        <GlobalCss />
        <BrowserRouter>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path={routePath.LOGIN} element={<Login />} />
              <Route path={routePath.DASHBOARD} element={<Dashboard />} />
              <Route path={routePath.MAIN} element={<Main />} />
              <Route
                path={routePath.ACCOUNT_MANAGE}
                element={<AccountManage />}
              />
              <Route path={routePath.NOTICE_LIST} element={<NoticeList />} />
              <Route path={routePath.NOTICE_VIEW} element={<NoticeView />} />
              <Route path={routePath.NOT_FOUND} element={<NotFound />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </SSEContainer>

      <ErrorModal />
      <CompleteModal />
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: ${({ theme }) => theme.ROOT_BG};
  padding: 0 18px;
  min-width: 1563px;
`;

export default App;
