import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { convertDate, customError, customErrorMsg } from 'services/miscFunc';
import { setErrorData } from 'store/modules/error';
export const ONE_DAY = 1000 * 60 * 60 * 24;

const plusEndDate = 1000 * 59 + 999;
// 59.999 차이

const useDate = () => {
  const today = new Date().getTime();
  const [startDate, setStartDate] = useState(today - ONE_DAY);
  const [endDate, setEndDate] = useState(today);
  const dispatch = useDispatch();

  const handleChangeStartDate = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    const convertNumDate = new Date(date).getTime();

    setStartDate(convertNumDate);
  };

  const handleChangeEndDate = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    const convertNumDate = new Date(date).getTime() + plusEndDate;

    setEndDate(convertNumDate);
  };

  const handleClickDateBtn = (beforeNum: number) => {
    setStartDate(endDate - ONE_DAY * beforeNum);
  };

  const handleClickDateRangeBtn = (type: string) => {
    if (type === 'thisMonth') {
      const now = new Date();
      const startOfMonth = new Date(
        now.getFullYear(),
        now.getMonth(),
        1,
      ).getTime();
      const endOfMonth =
        new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime() +
        plusEndDate;

      setStartDate(startOfMonth);
      setEndDate(endOfMonth);
    }
    if (type === 'thisWeek') {
      const now = new Date();
      const dayOfWeek = now.getDay();
      const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

      const monday = new Date(now);
      monday.setDate(now.getDate() + diffToMonday);
      monday.setHours(0, 0, 0, 0);
      const startTime = monday.getTime();

      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);
      sunday.setHours(23, 59, 59, 999);
      const endTime = sunday.getTime();

      setStartDate(startTime);
      setEndDate(endTime);
    }
    if (type === 'thisMonth') {
      const now = new Date();

      // 이번 달의 첫 번째 날 00:00:00
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const startTime = firstDayOfMonth.getTime();

      // 이번 달의 마지막 날 23:59:59.999
      const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      lastDayOfMonth.setHours(23, 59, 59, 999);
      const endTime = lastDayOfMonth.getTime();

      // 상태 업데이트
      setStartDate(startTime);
      setEndDate(endTime);
    }

    if (type === 'thisYear') {
      const now = new Date();

      const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
      const startTime = firstDayOfYear.getTime();

      const lastDayOfYear = new Date(now.getFullYear(), 11, 31);
      lastDayOfYear.setHours(23, 59, 59, 999);
      const endTime = lastDayOfYear.getTime();

      setStartDate(startTime);
      setEndDate(endTime);
    }
  };

  const handleInitDate = () => {
    const nowDate = new Date().getTime();
    setStartDate(nowDate - ONE_DAY);
    setEndDate(nowDate);
    //TODO
  };

  useEffect(() => {
    handleInitDate();
  }, []);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onChangeStartDate: handleChangeStartDate,
    onChangeEndDate: handleChangeEndDate,
    onClickDateBtn: handleClickDateBtn,
    onClickDateRangeBtn: handleClickDateRangeBtn,
    onChangeInitDate: handleInitDate,
  };
};
//TODO: 함수 date event로 고치기
export default useDate;
