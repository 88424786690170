/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
interface IProps {
  isManager: boolean;
  setOnAlarmSetModal: any;
  setOnAlarmListModal: any;
}
const HeaderAlarm: React.FC<IProps> = ({
  isManager,
  setOnAlarmSetModal,
  setOnAlarmListModal,
}) => {
  return (
    <Tooltip
      title={
        <Menu>
          {isManager && (
            <MenuItem onClick={() => setOnAlarmSetModal(true)}>
              <NotificationAddIcon />
              <Typography fontSize={14}>알람 설정</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={() => setOnAlarmListModal(true)}>
            <FormatListNumberedIcon />
            <Typography fontSize={14}>알람 목록</Typography>
          </MenuItem>
        </Menu>
      }
    >
      <NotiIcon />
    </Tooltip>
  );
};

const NotiIcon = styled(NotificationsIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Menu = styled.div`
  /* background-color: #333; */
  border-radius: 4px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 14px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #444;
  }
`;

export default HeaderAlarm;
