/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from 'constants/colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as PlusWhiteSVG } from 'assets/img/plusWhite.svg';
import * as _ from 'lodash';
import {
  convertArrayToObject,
  findEmptyString,
  sortSubArray,
} from 'services/miscFunc';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Tooltip } from '@mui/material';
import InfinityTooltip from 'components/InfinityTooltip/InfinityTooltip';
import { tooltipLocation } from 'services/libOptions';

const PopoverContent = ({
  onChangeInput,
  data,
  hData,
  idx,
  popoverClose,
}: {
  onChangeInput: (
    value: string | number | object,
    key: string,
    idx: number,
    popoverClose?: any,
  ) => void;
  data: any;
  hData: any;
  idx: number;
  popoverClose?: any;
}) => {
  const initRangeData = convertArrayToObject(data[hData.en] || []);
  const [rangeData, setRangeData] = useState<any>(initRangeData);
  // {0: [0, 0], 1: [0, 0]}
  const dataKeys = Object.keys(rangeData);

  const onClickPlusRange = () => {
    const lastKey =
      Object.keys(rangeData).length > 0
        ? Number(Object.keys(rangeData).slice(-1)[0])
        : -1;

    setRangeData({ ...rangeData, [lastKey + 1]: ['', ''] });
  };

  const onClickDeleteBtn = (key: string) => {
    delete rangeData[key];
    setRangeData(_.cloneDeep(rangeData));
  };

  return (
    <PopoverContentWrap>
      <div>
        <PopoverTitle>범위 설정</PopoverTitle>
        <PlusButton onClick={onClickPlusRange}>
          <PlusWhiteSVG /> 추가
        </PlusButton>
      </div>
      <RangeDiv>
        {dataKeys.map((key: string, idx: number) => {
          const leftVal = rangeData[key][0];
          const rightVal = rangeData[key][1];

          return (
            <InputDiv key={key}>
              {leftVal === '-Infinity' ? (
                <InfinityWrap>- ∞</InfinityWrap>
              ) : (
                <input
                  type="number"
                  value={leftVal}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRangeData({
                      ...rangeData,
                      [key]: [e.target.value, rightVal],
                    })
                  }
                />
              )}

              <InputSpan>~</InputSpan>

              {rightVal === 'Infinity' ? (
                <InfinityWrap>+ ∞</InfinityWrap>
              ) : (
                <input
                  type="number"
                  value={rightVal}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRangeData({
                      ...rangeData,
                      [key]: [leftVal, e.target.value],
                    })
                  }
                />
              )}
              <IconWrap>
                <Tooltip
                  title={
                    <InfinityTooltip
                      rangeData={rangeData}
                      setRangeData={setRangeData}
                      keyIdx={key}
                    />
                  }
                  placement="top"
                  PopperProps={tooltipLocation}
                >
                  <AllInclusiveIcon sx={{ cursor: 'pointer' }} />
                </Tooltip>
                <Tooltip title="범위 삭제">
                  <DeleteBtn onClick={() => onClickDeleteBtn(key)}>
                    <DeleteIcon />
                  </DeleteBtn>
                </Tooltip>
              </IconWrap>
            </InputDiv>
          );
        })}
        {!dataKeys.length && <EmptyWrap>범위를 추가해주세요.</EmptyWrap>}
      </RangeDiv>
      <div>
        <PopoverBtn
          disabled={findEmptyString(Object.values(rangeData))}
          $disabled={findEmptyString(Object.values(rangeData))}
          onClick={() => {
            const rangeValArr: [string, string][] = Object.values(rangeData);
            onChangeInput(
              sortSubArray(rangeValArr),
              hData.en,
              idx,
              popoverClose,
            );
          }}
        >
          확인
        </PopoverBtn>
      </div>
    </PopoverContentWrap>
  );
};

const PopoverContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  overflow: hidden;
`;

const PopoverTitle = styled.span`
  color: ${colors.NEUTRAl_300};
  font-size: 20px;
`;

const PlusButton = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  float: right;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const PopoverBtn = styled.button<{ $disabled: boolean }>`
  position: absolute;
  bottom: 15px;
  padding: 8px 24px;
  width: 250px;
  align-self: flex-end;
  background:
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      )
      #196bcb;
  background-blend-mode: multiply, multiply;
  color: ${colors.WHITE};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  input {
    width: 80px;
    height: 30px;
    border-radius: 6px;
    text-align: center;
  }
  animation: fadein 0.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const InputSpan = styled.span``;

const RangeDiv = styled.div`
  height: 120px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 15px;
    margin-right: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
`;

const DeleteBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
`;

const EmptyWrap = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrap = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 6px;
`;

const InfinityWrap = styled.span`
  width: 80px;
  height: 30px;
  border-radius: 6px;
  color: black;
  text-align: center;
  padding-top: 7px;
  padding-right: 2px;
  font-size: 16px;
  background-color: ${colors.WHITE};
`;

export default PopoverContent;
