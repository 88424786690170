/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction } from 'react';
import { colors } from 'constants/colors';
import { css, styled } from 'styled-components';
import { rowT, tableHeaderType } from 'types/table-T';
import { switchKeyData, tablePageInfo } from 'services/miscFunc';
import Pagination from 'components/common/Pagination';
import { ReactComponent as DeleteSVG } from 'assets/img/delete.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip } from '@mui/material';

interface IProps {
  pageNum: number;
  tableHeaderData: tableHeaderType[];
  tableData: rowT[];
  isOption?: boolean;
  children?: React.ReactNode;
  curPage: number;
  articlePerPage: number;
  paginationData?: any;
  setCurPage: React.Dispatch<SetStateAction<number>>;
  onClickAddBtn?: () => void;
  onClickUpdateBtn?: (data: rowT) => void;
  onClickDeleteBtn?: (data: rowT) => void;
  onClickTableTr?: (id: number) => void;
}
const TablePanel: React.FC<IProps> = ({
  pageNum,
  tableHeaderData,
  tableData,
  isOption,
  curPage,
  articlePerPage,
  children,
  paginationData,
  setCurPage,
  onClickAddBtn,
  onClickUpdateBtn,
  onClickDeleteBtn,
  onClickTableTr,
}) => {
  const isAccount = pageNum === tablePageInfo.ACCOUNT_MANAGE;
  const isControllerLog = pageNum === tablePageInfo.CONTROLLER_LOG;

  return (
    <Wrap $isAccount={isAccount}>
      {children}
      <Table>
        <HeaderWrap>
          <tr>
            {tableHeaderData!.map((data: tableHeaderType) => (
              <Th key={data.kr}>{data.kr}</Th>
            ))}
          </tr>
        </HeaderWrap>
        <BodyWrap $isTrClick={onClickTableTr ? true : false}>
          {tableData!
            .slice((curPage - 1) * articlePerPage, articlePerPage * curPage)
            .map((data: any, idx: number) => (
              <TableTr
                key={data.id}
                $isControllerLog={isControllerLog}
                $idx={idx}
                onClick={() => onClickTableTr && onClickTableTr(data.id)}
              >
                {tableHeaderData!.map((hData: tableHeaderType) => {
                  const keys = Object.keys(data);
                  const hasDataKey = keys.includes(hData.en);
                  const keyData = switchKeyData(pageNum)(data, hData.en);

                  if (hData.en === 'Index') {
                    return (
                      <Td key={data.id}>
                        {(curPage - 1) * articlePerPage + (idx + 1)}
                      </Td>
                    );
                  }

                  if (hData.en === 'ReverseIndex') {
                    const totalItems = paginationData?.totalElements ?? 0;
                    const itemsPerPage = 20;
                    const startIdx =
                      totalItems -
                      ((paginationData?.curPage || curPage) - 1) * itemsPerPage;

                    return <Td key={1}>{startIdx - idx}</Td>;
                  }

                  if (hasDataKey) {
                    return <Td key={hData.kr}>{keyData}</Td>;
                  }

                  if (isOption) {
                    const isUserAccount = data.role === 'USER';
                    return (
                      <Td $option key="옵션">
                        <Tooltip title="수정">
                          <UpdateIcon
                            onClick={() => onClickUpdateBtn?.(data)}
                            sx={{
                              color: colors.WHITE,
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </Tooltip>

                        {isUserAccount && (
                          <Tooltip title="삭제">
                            <DeleteIcon
                              onClick={() => onClickDeleteBtn?.(data)}
                            />
                          </Tooltip>
                        )}
                      </Td>
                    );
                  }
                })}
              </TableTr>
            ))}
        </BodyWrap>
      </Table>
      <Pagination
        curPage={curPage}
        data={tableData}
        articlePerPage={articlePerPage}
        setCurPage={setCurPage}
      />
    </Wrap>
  );
};

const tdOptionCss = css`
  display: flex;
  justify-content: center;
  gap: 18px;
  align-items: center;
`;

const tablePadding = css`
  padding-left: 244px;
  padding-top: 74px;
`;

const tableMargin = css`
  margin-left: 254px;
  margin-top: 74px;
`;

const Wrap = styled.div<{ $isAccount: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 18px 24px;
  ${({ $isAccount }) => $isAccount && tableMargin};
  justify-content: space-between;
  font-family: 'PretendardVariable';
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  border-radius: 16px;
  svg {
    fill: ${({ theme }) => theme.TEXT_PRIMARY};
  }
`;

const Table = styled.table`
  min-width: 100%;
  max-height: 100%;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  /* height: 100%; */
`;

const HeaderWrap = styled.thead`
  background: ${({ theme }) => theme.TABLE_HEADER_BG};
`;

const BodyWrap = styled.tbody<{ $isTrClick?: boolean }>`
  width: 100%;
  ${({ $isTrClick }) =>
    $isTrClick &&
    `
    cursor: pointer;
    tr:hover {
      background: #77a4cf22;
    }
  `}
`;

const TableTr = styled.tr<{ $isControllerLog: boolean; $idx: number }>`
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  ${({ $isControllerLog, $idx, theme }) =>
    $isControllerLog &&
    `background-color: ${
      $idx % 2 === 0 ? theme.TABLE_TR_COLOR1 : theme.TABLE_TR_COLOR2
    };
  `}
  > tr {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
`;

const Th = styled.th`
  padding: 12px 4px;
  font-size: 15px;
  color: ${({ theme }) => theme.WHITE};
  font-family: 'PretendardVariable';
  font-weight: 600;
`;

const Td = styled.td<{ $option?: boolean }>`
  text-align: center;
  padding: 8px 3px;
  font-size: 13px;
  ${({ $option }) => $option && tdOptionCss};
`;

const OptionBtn = styled.button`
  border: none;
  font-size: 13px;
  font-family: 'Pretendard_R';
  background-color: ${colors.NEUTRAl_50};
  border-radius: 2px;

  &:hover {
    background-color: rgb(91, 155, 213);
    color: ${colors.WHITE};
    transition: 0.15s;
  }
`;

const UpdateIcon = styled(RefreshIcon)`
  cursor: pointer;
`;

const DeleteIcon = styled(DeleteSVG)`
  width: 18px;
  height: 18px;
  fill: ${colors.WHITE};
  cursor: pointer;
`;

export default TablePanel;
