import ModalPortal from 'components/common/ModalPortal';
import { getDefaultWrap } from 'components/common/modalCss';
import React, { MouseEvent, useState } from 'react';
import { ACCOUNT_DESC } from 'services/modal-text';
import { useAppSelect } from 'store/configureStore.hooks';
import { styled } from 'styled-components';
import { tableHeaderType } from 'types/table-T';
import { colors } from 'constants/colors';
import ChangeAccountPw from 'components/ModalContents/ChangeAccountPw';
interface IProps {
  onClose: () => void;
}
const AccountInfo: React.FC<IProps> = ({ onClose }) => {
  const [onPwModal, setOnPwModal] = useState(false);
  const accountInfo = useAppSelect((state) => state.accountInfo.info);

  const handleClickInner = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const { role, loginId, lastLoginTime, accountName } = accountInfo;
  const orderedData = [
    role,
    loginId,
    accountName,
    lastLoginTime.slice(0, 16).replace('T', ' '),
  ];

  return (
    <ModalPortal>
      <ModalBg onClick={onClose}>
        <ModalContent onClick={handleClickInner}>
          <Wrap>
            {ACCOUNT_DESC.map((info: tableHeaderType, idx: number) => (
              <ItemWrap key={info.kr}>
                <span>{info.kr}</span>
                <span>{orderedData[idx]}</span>
              </ItemWrap>
            ))}
            <ItemWrap>
              <span>비밀번호 변경</span>
              <button onClick={() => setOnPwModal(true)}>변경</button>
            </ItemWrap>
            {onPwModal && (
              <ChangeAccountPw
                accountInfo={accountInfo}
                onClose={() => setOnPwModal(false)}
              />
            )}
          </Wrap>
        </ModalContent>
      </ModalBg>
    </ModalPortal>
  );
};

const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Wrap = styled.div`
  ${getDefaultWrap};
  position: absolute;
  top: 60px;
  right: 32px;
  width: 380px;
  font-size: 13px;
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;

  > span:nth-child(1) {
    width: 110px;
  }

  > button {
    width: 80px;
    border-radius: 4px;
    border: 1px solid ${colors.BLUE_BASE};
    background-color: ${colors.BLUE_BASE};
    color: ${colors.WHITE};
    font-size: 12px;
  }
`;

export default AccountInfo;
