import { useQuery } from '@tanstack/react-query';
import { getAddrCoordinates, getWeatherData } from 'apis/weather-rest';
import { queryKeys } from 'services/queryKeys';

export type weatherPrms = {
  x: string;
  y: string;
  isLong: boolean;
};

export const useCoordinates = (addr: string) => {
  return useQuery(
    [queryKeys.WEATHER, 'coordinates'],
    () => getAddrCoordinates(addr),
    {
      enabled: addr.length > 0,
    },
  );
};

export const useWeather = (prms: weatherPrms) => {
  return useQuery(
    [queryKeys.WEATHER, `weather-${prms.isLong}`],
    () => getWeatherData(prms),
    {
      enabled: !!prms.x && !!prms.y,
    },
  );
};
