import React from 'react';
import { FormEvent, useState } from 'react';
import IemsLayout from 'layout/IemsLayout';
import TablePanel from 'components/TablePanel/TablePanel';
import { ACCOUNT_MANAGE, NOTICE } from 'services/table-text';
import AccountModal from 'components/ModalContents/AccountModal';
import { rowT } from 'types/table-T';
import { account } from 'types/account-T';
import DeleteOptionModal from 'components/ModalContents/DeleteOptionModal';
import { useAppSelect } from 'store/configureStore.hooks';
import Nav from 'components/Nav/Nav';
import { useDispatch } from 'react-redux';
import { setConfirmData } from 'store/modules/confirm';
import { customConfirm, tablePageInfo } from 'services/miscFunc';
import { useAccount, useDeleteAccount } from 'apiHooks/useAccount';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { ReactComponent as PlusWhiteSVG } from 'assets/img/plusWhite.svg';
import { AddCircleRounded } from '@mui/icons-material';
import { useNotice } from 'apiHooks/useNotice';
import routePath from 'constants/route';
import { useNavigate } from 'react-router-dom';
export const initialValues = {
  id: -1,
  loginId: '',
  name: '',
  email: '',
  phone: '',
  role: '',
  lastLoginTime: '',
};

const NoticeList = () => {
  const [clickedHeaderBtn, setClickedHeaderBtn] = useState(false);
  const [rowData, setRowData] = useState<account>(initialValues);
  const [onUpdateModal, setOnUpdateModal] = useState(false);
  const [onDeleteModal, setOnDeleteModal] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useAppSelect((state) => state.accountInfo.info);
  const { mutateAsync: mutateDeleteAsync } = useDeleteAccount();
  const articlePerPage = 15;

  const { data: noticeData = [], refetch } = useNotice();

  const handleClickHeaderBtn = () => {
    setClickedHeaderBtn(!clickedHeaderBtn);
  };

  const handleClickUpdate = (data: rowT) => {
    setRowData(data);
    setOnUpdateModal(true);
  };

  const handleClickDelete = (data: rowT) => {
    setRowData(data);
    setOnDeleteModal(true);
  };

  const onClickTableTr = (id: number) => {
    navigate(`${routePath.NOTICE_VIEW}?id=${id}`);
  };

  const handleDeleteInfo = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await mutateDeleteAsync(rowData.id!);
    if (result?.success) {
      dispatch(setConfirmData(customConfirm('요청이 적용되었습니다.')));
      refetch();
      setOnDeleteModal(false);
    }
  };

  return (
    <>
      <IemsLayout
        nav={<Nav />}
        main={
          <TablePanel
            pageNum={tablePageInfo.ACCOUNT_MANAGE}
            tableHeaderData={NOTICE}
            curPage={curPage}
            articlePerPage={articlePerPage}
            setCurPage={setCurPage}
            tableData={noticeData?.contents ?? []}
            onClickTableTr={(id: number) => onClickTableTr(id)}
            onClickUpdateBtn={handleClickUpdate}
            onClickDeleteBtn={handleClickDelete}
            paginationData={{
              totalElements: noticeData?.totalElements,
              curPage: noticeData?.currentPage,
            }}
          >
            <TitleWrap>
              <TableTitle>공지사항</TableTitle>
              {/* <div onClick={handleClickHeaderBtn}>
                <PlusIcon /> <span>추가</span>
              </div> */}
            </TitleWrap>
          </TablePanel>
        }
      />
    </>
  );
};

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 18px 24px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
`;

const TableTitle = styled.span`
  font-family: 'GmarketSans_R';
  font-size: 19px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
`;

const PlusIcon = styled(AddCircleRounded)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
`;

export default NoticeList;
