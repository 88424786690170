/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PaletteIcon from '@mui/icons-material/Palette';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { useAppSelect } from 'store/configureStore.hooks';
import { useDispatch } from 'react-redux';
import { setThemeData } from 'store/modules/theme';
import { DarkMode, LightMode } from '@mui/icons-material';
import { primaryColors } from 'constants/colors';

const PaletteSx = { width: '24px', height: '24px', cursor: 'pointer' };
interface IProps {
  isManager: boolean;
  onAccountInfo: boolean;
  setAccountInfo: any;
  onClickAccount: () => void;
}
const HeaderAppearance: React.FC<IProps> = ({
  isManager,
  onAccountInfo,
  setAccountInfo,
  onClickAccount,
}) => {
  const dispatch = useDispatch();
  const theme = useAppSelect((state) => state.theme);

  const onClickModeButton = () =>
    dispatch(
      setThemeData({
        ...theme.data,
        mode: theme.data.mode === 'lightTheme' ? 'darkTheme' : 'lightTheme',
      }),
    );

  const onClickColorButton = (color: string) =>
    dispatch(
      setThemeData({
        ...theme.data,
        primary: color,
      }),
    );

  return (
    <Tooltip
      title={
        <Wrap>
          <Title>모드</Title>
          <ModeChange onClick={onClickModeButton}>
            {theme.data.mode === 'lightTheme' && <DarkMode />}
            {theme.data.mode === 'darkTheme' && <LightMode />}
          </ModeChange>
          <Title>색상</Title>
          <ColorChangeWrap>
            {primaryColors.map((color: string) => (
              <ColorChange
                key={color}
                $color={color}
                $isSelected={color === theme.data.primary}
                onClick={() => onClickColorButton(color)}
              />
            ))}
          </ColorChangeWrap>
        </Wrap>
      }
    >
      <PaletteIcon sx={PaletteSx} />
    </Tooltip>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-family: 'PretendardVariable';
  padding: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ModeChange = styled.span`
  background: ${({ theme }) => theme.MODE_CHANGE_BG};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: ease-in-out 0.2s;
  cursor: pointer;

  svg {
    width: 18px;
    color: ${({ theme }) => theme.MODE_CHANGE_COLOR};
  }
`;

const ColorChange = styled.span<{ $color: string; $isSelected: boolean }>`
  background: ${({ $color }) => $color};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  position: relative;

  &::after {
    content: '✓';
    position: absolute;
    color: white;
    font-size: 18px;
    display: ${({ $isSelected }) => ($isSelected ? 'block' : 'none')};
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
`;

const ColorChangeWrap = styled.div`
  display: flex;
  gap: 10px;
`;

export default HeaderAppearance;
