/* eslint-disable no-useless-escape */
import { customError } from 'services/miscFunc';
import store from 'store/configureStore';
import { setErrorData } from 'store/modules/error';
import { account } from 'types/account-T';
import { alarmCaseType } from 'types/alarm-T';

export const validationPassword = (password: string) => {
  if (password.length < 8 || password.length > 16) {
    return {
      status: false,
      message: '비밀번호 조건에 해당되지 않습니다.',
    };
  }

  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  const isRightPw =
    (hasUppercase || hasLowercase) && hasNumber && hasSpecialCharacter;
  return {
    status: isRightPw,
    message: isRightPw ? '' : '비밀번호 조건에 해당되지 않습니다.',
  };
};

export const validationAccount = (data: account) => {
  const { loginId, password, isSamePassword } = data;
  if (!password || !isSamePassword) {
    return {
      status: false,
      message: '비밀번호를 입력해주세요.',
    };
  }
  if (password !== isSamePassword) {
    return {
      status: false,
      message: '비밀번호가 일치하지 않습니다.',
    };
  }

  const isValidationPw =
    validationPassword(password!).status &&
    validationPassword(isSamePassword!).status;
  if (!isValidationPw) {
    return {
      status: false,
      message: '비밀번호 조건에 해당되지 않습니다.',
    };
  }

  if (!loginId) {
    return {
      status: false,
      message: '계정 정보를 입력해주세요.',
    };
  }

  return {
    status: true,
    message: '',
  };
};
