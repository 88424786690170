import { useAlarmListStatus, useAlarmListType } from 'apiHooks/useAlarm';
import React, { SetStateAction } from 'react';
import { cvtAlarmStatus, cvtAlarmType } from 'services/miscFunc';
import styled from 'styled-components';
import { domainT } from 'types/dashboard-T';
interface IProps {
  domainList: domainT[];
  alarmType: string;
  alarmStatus: string;
  dashboardId: number;
  setAlarmType: React.Dispatch<SetStateAction<string>>;
  setAlarmStatus: React.Dispatch<SetStateAction<string>>;
  setDashboardId: React.Dispatch<SetStateAction<number>>;
}

const AlarmListSelect: React.FC<IProps> = ({
  domainList,
  alarmType,
  alarmStatus,
  dashboardId,
  setAlarmStatus,
  setAlarmType,
  setDashboardId,
}) => {
  const { data: listType = [] } = useAlarmListType();
  const { data: listStatus = [] } = useAlarmListStatus();

  return (
    <SelectWrap>
      <span>알람 종류</span>
      <select
        name="type"
        value={alarmType}
        onChange={(e) => setAlarmType(e.target.value)}
      >
        <option value="ALL">전체</option>
        {listType.map((type: string) => (
          <option key={type} value={type}>
            {cvtAlarmType(type)}
          </option>
        ))}
      </select>
      <span>상태</span>
      <select
        name="type"
        value={alarmStatus}
        onChange={(e) => setAlarmStatus(e.target.value)}
      >
        <option value="ALL">전체</option>
        {listStatus.map((status: string) => (
          <option key={status} value={status}>
            {cvtAlarmStatus(status)}
          </option>
        ))}
      </select>
      <span>설비명</span>
      <select
        name="type"
        value={dashboardId}
        onChange={(e) => setDashboardId(Number(e.target.value))}
      >
        <option value={-1}>전체</option>
        {domainList.map((domain: domainT) => (
          <option key={domain.id} value={domain.id}>
            {domain.name}
          </option>
        ))}
      </select>
    </SelectWrap>
  );
};

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  > select {
    width: 120px;
    text-align: center;
    padding: 5px 4px;
    border-radius: 4px;
    outline: 0;
    border: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}22 !important;
    background-color: ${({ theme }) => theme.BUTTON_SECONDARY};
    color: ${({ theme }) => theme.BUTTON_SECONDARY_COLOR};
  }
`;

export default AlarmListSelect;
