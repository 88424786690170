/* eslint-disable @typescript-eslint/no-explicit-any */
import AlarmCaseDesc from 'components/AlarmCaseDesc/AlarmCaseDesc';
import CommonTable from 'components/TablePanel/CommonTable';
import React, { SetStateAction } from 'react';
import { subjectAlarm } from 'services/enum';
import { ALARM_CASE_SET } from 'services/table-text';
import styled from 'styled-components';
import { alarmCaseType } from 'types/alarm-T';
interface IProps {
  data: alarmCaseType[];
  setData: React.Dispatch<SetStateAction<alarmCaseType[]>>;
  onClickDeleteBtn: (data: any) => void;
}
const AlarmCaseSetting: React.FC<IProps> = ({
  data,
  setData,
  onClickDeleteBtn,
}) => {
  const tooltipItem = {
    title: 'priority',
    node: <AlarmCaseDesc />,
  };

  return (
    <CaseTableWrap>
      <CommonTable
        tooltipItem={tooltipItem}
        tableHeaderData={ALARM_CASE_SET}
        tableData={data}
        isDraggable
        subjectType={subjectAlarm.CASE_SETTING}
        setTableData={setData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </CaseTableWrap>
  );
};

const CaseTableWrap = styled.div`
  width: 100%;
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 10px 10px 0 0;

  input,
  select {
    border: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}66;
  }
`;

export default AlarmCaseSetting;
