import { customErrorMsg } from 'services/miscFunc';
import { alarmCaseType } from 'types/alarm-T';

export const validationCaseName = (
  postData: alarmCaseType[],
  putData: alarmCaseType[],
) => {
  const concatData = [...postData, ...putData];
  const isCaseNameErr = concatData.find((item) => item.caseName.length === 0);
  if (isCaseNameErr) {
    customErrorMsg('레벨명을 모두 입력해주세요.');
    return false;
  }
  return true;
};

export const validationPeriodMinute = (
  postData: alarmCaseType[],
  putData: alarmCaseType[],
  data: alarmCaseType[],
) => {
  const concatData = [...postData, ...putData];
  const isPeriodErr = concatData.find(
    (item: alarmCaseType) => item.periodMinute <= 0,
  );

  const isEmptyPeriodMinute = data.find((item) => item.periodMinute === 0);

  if (isPeriodErr || isEmptyPeriodMinute) {
    customErrorMsg(
      `알람 주기는 공백 혹은 1분 미만으로 선택할 수 없습니다.\n다시 설정해주세요.`,
    );
    return false;
  }
  return true;
};
