/* eslint-disable @typescript-eslint/no-explicit-any */
import store from 'store/configureStore';
import { api, apiError } from './common';

export const getNoticeList = async (prms: any) => {
  try {
    const { data } = await api.get(
      `/notices?page=${prms.page || 0}&size=${prms.size || 20}${
        prms.display ? `&display=${prms.display}` : ''
      }`,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getNoticeView = async (prms: any) => {
  try {
    const { data } = await api.get(`/notices/${prms.id}`);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
