/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from 'components/common/Modal';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { getDefaultWrap } from 'components/common/modalCss';
import { colors } from 'constants/colors';
import { ALARM_SET_MENU_OPT } from 'services/modal-text';

import _ from 'lodash';
import AlarmMenuList from 'components/AlarmMenuList/AlarmMenuList';
import ModalTitle from 'components/ModalContents/common/ModalTitle';

interface IProps {
  selectedMenu: string;
  setSelectedMenu: any;
  main: React.ReactNode;
  children?: React.ReactNode;
  addModalBtn?: React.ReactNode;
  onClose: () => void;
  onClickSave: () => void;
}

const SetAlarmLayout: React.FC<IProps> = ({
  main,
  selectedMenu,
  setSelectedMenu,
  addModalBtn,
  children,
  onClose,
  onClickSave,
}) => {
  const title = ALARM_SET_MENU_OPT.find((item) => item.en === selectedMenu)?.kr;

  return (
    <Modal>
      <Wrap>
        <ModalTitle title="알람 설정" onClose={onClose} />
        <ContentWrap>
          <AlarmMenuList
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
          <MainWrap>
            <TitleWrap>
              <Title>{title}</Title>
              {children}
            </TitleWrap>
            {main}
            <ButtonWrap>
              {addModalBtn && addModalBtn}
              <ModalBtn onClick={onClose}>취소</ModalBtn>
              <ModalBtn onClick={onClickSave}>저장</ModalBtn>
            </ButtonWrap>
          </MainWrap>
        </ContentWrap>
      </Wrap>
    </Modal>
  );
};

//children은 TitleWrap의 버튼으로 사용

const Wrap = styled.div`
  ${getDefaultWrap};
  animation: none;
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  width: 1500px;
  height: 640px;
`;

const ContentWrap = styled.div`
  display: flex;
  height: 100%;
`;

const MainWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 32px;
  gap: 24px;
  width: calc(1500px - 250px - 48px);

  & span {
    color: ${({ theme }) => theme.TEXT_PRIMARY};
  }
`;

const TitleWrap = styled.div`
  width: 100%;
  display: block;
`;

const Title = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${colors.NEUTRAl_300};
  font-size: 22px;
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  gap: 18px;
  display: flex;
`;

const ModalBtn = styled.button`
  width: 80px;
  padding: 8px 24px;
  color: ${({ theme }) => theme.WHITE};
  background: ${({ theme }) => theme.BUTTON_THIRD};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
`;

export default SetAlarmLayout;

// 1. 메뉴 선택 따로 컴포넌트로 빼기
// 2. HeaderWrap ModalTitle로 변경
// 3. 이 컴포넌트를 Layout형태로 빼고, Children으로 컨텐츠 받기
