/* eslint-disable @typescript-eslint/no-explicit-any */
import { weatherPrms } from 'apiHooks/useWeather';
import axios from 'axios';
import dayjs from 'dayjs';
import { customErrorMsg, cvtNxNy, weatherBaseTime } from 'services/miscFunc';

export const getWeatherData = async (info: weatherPrms) => {
  const { x, y, isLong } = info;
  const { nx, ny } = cvtNxNy(Number(y), Number(x));
  // 초단기 실황조회 : getUltraSrtNcst
  // 초단기 예보조회 : getUltraSrtFcst
  // 단기예보조회    : getVilageFcst

  const nowDate = dayjs();
  const baseDate = nowDate.format('YYYYMMDD');
  const baseTime = weatherBaseTime(isLong);
  const url = `https://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/`;
  const ncstUrl = url + 'getUltraSrtNcst';
  const fcstUrl = url + 'getUltraSrtFcst';
  const vFcstUrl = url + 'getVilageFcst';

  let prms = `?serviceKey=${process.env.REACT_APP_WEATHER_KEY}`;
  prms += '&dataType=JSON';
  prms += '&pageNo=1';
  prms += '&numOfRows=100';
  prms += `&base_date=${baseDate}`;
  prms += `&base_time=${baseTime}`;
  prms += `&nx=${nx}`;
  prms += `&ny=${ny}`;
  console.log(isLong, baseTime);
  if (isLong) {
    // 단기 예보(3일치)
    try {
      const { data } = await axios.get(vFcstUrl + prms);
      console.log(data);
      return data;
    } catch (e: any) {
      customErrorMsg('날씨 정보를 불러오는 중 오류가 발생하였습니다.');
    }
  } else {
    try {
      const [ncstRes, fcstRes] = await Promise.all([
        axios.get(ncstUrl + prms),
        axios.get(fcstUrl + prms),
      ]);

      const ncstData = ncstRes.data.response.body.items.item;
      const fcstData = fcstRes.data.response.body.items.item;
      // 제조하여 보내기
      const nearTime = fcstData[0].fcstTime;
      const filteredSkyData = fcstData.filter(
        (item: any) => item.fcstTime === nearTime && item.category === 'SKY',
      )[0].fcstValue;
      return { data: ncstData, skyData: filteredSkyData };
    } catch (e: any) {
      customErrorMsg('날씨 정보를 불러오는 중 오류가 발생하였습니다.');
    }
  }
  // const { data } = await axios.get(url + prms);
  // console.log(data);
  // nx, xy (x, y 좌표) 예보지점의 값
  // base_date, base_time 발표일자 및 시각
  // pageNo, numOfRows 페이지 번호 및 한 페이지 결과 수
};

export const getAddrCoordinates = async (addr: string) => {
  // 사용 X
  try {
    const url = 'https://dapi.kakao.com/v2/local/search/address.json';
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_DEVELOP_KEY}`,
      },
      params: {
        query: addr,
      },
    });

    console.log(data);

    if (data.documents.length > 0) {
      const { x, y } = data.documents[0];
      return { y: y, x: x };
    }
    return { x: '', y: '' };
  } catch (e: any) {
    customErrorMsg(
      `날씨 정보에 필요한 좌표 탐색에 실패하였습니다.\nIEMS ADMIN에서 기업 주소를 다시 설정해주세요.`,
    );
  }
};
